import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "./PointOfInterestModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function PointOfInterestModal({ pointOfInterest, isOpen, onClosed, selectedPictureIndex }) {
    PointOfInterestModal.displayName = PointOfInterestModal.name;

    const handlerToggle = () => {
        onClosed();
    }

    return (
        <Modal
            fullscreen
            isOpen={isOpen}
            toggle={handlerToggle}
        >
            <ModalHeader>{pointOfInterest?.title || ""}</ModalHeader>
            <ModalBody>
                {pointOfInterest && pointOfInterest.pictures && pointOfInterest.pictures.length > 0 &&
                    <Carousel showThumbs={false} selectedItem={selectedPictureIndex} dynamicHeight={true}>
                        {pointOfInterest.pictures.map((image, _) => <div key={image.id} className="point-of-interest-modal-picture-container">
                            <img src={`/picture/${image.id}/600`} className='point-of-interest-modal-picture'/>
                            <div className="point-of-interest-modal-picture-meta-data">
                                {(pointOfInterest.author || image.title || image.description || image.reference) &&
                                    <div className="legend customized">
                                        {pointOfInterest.author && <>
                                            <img src={`/picture/profile-picture/${pointOfInterest.author.id}/default`} alt={pointOfInterest.author.name} style={{ borderRadius: '50%', width: '30px' }} />
                                            &nbsp;
                                            <p>{pointOfInterest.author.name}</p>
                                        </>
                                        }
                                        {image.title && <h5>{image.title || ""}</h5>}
                                        {image.description && <><p>{image.description || ""}</p></>}
                                        {image.reference && <><p>{image.reference}</p></>}
                                    </div>
                                }
                            </div>
                        </div>)}
                    </Carousel>
                }
                <hr />
                {pointOfInterest?.shortDescription && <div className="preline-enabled"><h3>Short Description</h3>{pointOfInterest?.shortDescription || ""}</div>}
                {pointOfInterest?.longDescription && <div className="preline-enabled"><h3>Long Description</h3>{pointOfInterest?.longDescription || ""}</div>}
                {pointOfInterest?.references && <div className="preline-enabled"><h3>References</h3>{pointOfInterest?.references || ""}</div>}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handlerToggle}>
                    <FontAwesomeIcon icon={faCheck}/>
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default PointOfInterestModal;