//https://jasonwatmore.com/post/2021/09/17/react-fetch-set-authorization-header-for-api-requests-if-user-logged-in
import { useRecoilState } from 'recoil';
import { authTokenAtom } from './auth-token-atoms'; // used in recoil

//https://jasonwatmore.com/post/2021/09/17/react-fetch-set-authorization-header-for-api-requests-if-user-logged-in
function useAuthenticatedFetchApi() {

    //const [auth, setAuth] = useRecoilState(authAtom);

    // authToken is set in user-action.js login function via authTokenAtom (available global state recoil)
    const [authToken, setAuthToken] = useRecoilState(authTokenAtom);

    // creates an object with four methods
    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE')
    };

    // https://jasonwatmore.com/post/2021/09/17/react-fetch-set-authorization-header-for-api-requests-if-user-logged-in
    function request(method) {
        return (url, body) => {
            const requestOptions = {
                method, 
                headers: authHeader()
            };
            if (body) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }
            return fetch(url, requestOptions).then(handleResponse, handleErrorResponse);
        }
    }
    
    
    // https://jasonwatmore.com/post/2021/09/17/react-fetch-set-authorization-header-for-api-requests-if-user-logged-in
    // gets the auth-header
    function authHeader() {
        const isLoggedIn = !!authToken;
        if (isLoggedIn) {
            // bearer - 'Träger' des Tokens, authorization for an api call. User is already authentificated.
            return { Authorization: `Bearer ${authToken}` };
        } else {
            return {};
        }
    }

    // https://jasonwatmore.com/post/2021/09/17/react-fetch-set-authorization-header-for-api-requests-if-user-logged-in
    //removes old auth-token, if request is not ok 
    function handleResponse(response) {

        // .then there no Promise.Resolve()
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                if ([401, 403].includes(response.status) || !authToken?.token) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    localStorage.removeItem('authToken');
                    setAuthToken(null);

                    //history.push('/login');
                    // redirect to login page
                    window.location.href = "/";
                }

                const error = (data && data.message) || response.statusText;

                return Promise.reject(error);
            }

            return data;
        });
    }

    function handleErrorResponse(reason) {
        window.location.href = "/";
    }
}

export { useAuthenticatedFetchApi };
