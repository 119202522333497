import md5 from 'md5-hash'

// Gets all voices
function useVoiceUtility() {
    return {
        getVoices,
        getVoiceById
    }

    function hashUri(uri) {
       
        // Object like this has no property that can be used as ID, so we remove all non-word-characters (spaces, comma, brackets, ...)
        // SpeechSynthesisVoice {
        // default: false
        // lang: "de-DE"
        // localService: true
        // name: "Microsoft Stefan - German (Germany)"
        // voiceURI: "Microsoft Stefan - German (Germany)"
        // }

        return md5(uri);
    }

    function loadVoices () {
        // speechSynthesis exists from the browser w3c-standard
        // https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesis
        const voices = speechSynthesis.getVoices();

        if (voices && voices.length > 0) {
            // returns list with id and name
            // map is like select
            return voices.map(systemVoice => {
                systemVoice.voiceId = hashUri(systemVoice.voiceURI);
                systemVoice.voiceName = systemVoice.name;

                return systemVoice;
            });
        } else {
            return [];
        }
    }

    async function getVoices() {

        // Promise, because we need a async for loadVoices
        // resolve .... resolves the promise :)
        return new Promise((resolve) => {
            // load all voices
            let voices = loadVoices();

            // It can happen, that the voices are not already loaded, so we wait for OnVoiceChanged
            if (!voices || voices.length === 0) {
                speechSynthesis.onvoiceschanged = () => {
                    // promise is resolved and we return the 
                    resolve(loadVoices());
                };
            } else {
                resolve(voices);
            }
        });
    }

    async function getVoiceById(voiceId) {
        const voices = await getVoices();
        const matchedVoices = voices.filter(voice => voice.voiceId == voiceId);

        if (matchedVoices && matchedVoices.length >= 1) {
            return matchedVoices[0];
        }
        // no match found, return first 
        else if (voices && voices.length > 0) {
            return voices[0];
        } else {
            return null;
        }
    }
}

export { useVoiceUtility };
