import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPerson } from '@fortawesome/free-solid-svg-icons'

function UserMarker() {
    UserMarker.displayName = UserMarker.name;
    return (
        <span className="userCurrentPositionInMap"><FontAwesomeIcon icon={faPerson} className="userCurrentPositionIcon" /></span>
    );
}

export default UserMarker;