import { useRecoilState } from 'recoil';
import { authTokenAtom } from './auth-token-atoms';
import jwt_decode from "jwt-decode";

function useUserActions() {
    // we save the auth-token in a global state by using recoil (npm)
    // we just need the setter
    const [_, setAuthToken] = useRecoilState(authTokenAtom);

    return {
        login,
        logout,
        currentUser,
        currentToken,
        isLoggedIn,
        updateUser
    }

    async function login(credentialResponse) {
        // credentialResponse: data with username + password

        // https://jasonwatmore.com/post/2021/09/07/react-recoil-jwt-authentication-tutorial-and-example
        const tokenBlob = new Blob( [JSON.stringify({ token: credentialResponse.credential }, null, 2)], //format json
                                    { type: 'application/json' }
                                  );
        const requestOptions = {
            method: 'POST',
            body: tokenBlob,
            cache: 'default'
        };

        // controller call
        const response = await fetch("/auth/google", requestOptions); 

        const loginResponse = await response.json();

        // data from our AuthController (serialized token)
        const token = loginResponse.token;
        const user = loginResponse.user;

        // saves the authtoken for the user in the local storage.
        localStorage.setItem("authToken", token);

        // save token also in global state (whole react app - recoil)
        setAuthToken(token);

        // saves the user in local storage (chrome). eg. name, picture url, e-mail
        updateUser(user);
    }

    function logout() {
        // remove user from local storage, set auth state to null and redirect to login page
        localStorage.removeItem('authToken');
        localStorage.removeItem("currentUser");
    }

    function updateUser(user) {
        localStorage.setItem("currentUser", JSON.stringify(user));
    }

    function currentUser() {
        // in localstorage just a string is possible
        const currentUserJson = localStorage.getItem("currentUser");

        if (!!currentUserJson) {
            try {
                // parse to json. 
                return JSON.parse(currentUserJson);
            } catch {
                return null;
            }
        } else {
            return null;
        }
    }

    
    function isLoggedIn() {
        const currentUser = this.currentUser();
        const decodedToken = this.currentToken();

        if (decodedToken) {
            const expired = (Date.now() >= decodedToken.exp * 1000);

            return !expired && !!currentUser;
        } else {
            return false;
        }
    }

    function currentToken() {
        // get token as string from the local storage
        const token = localStorage.getItem("authToken");

        // get an json web token
        return (!!token) ? jwt_decode(token) : null;
    }
}

export { useUserActions };