import React, { useState, useEffect } from 'react';
import { useUserActions } from '../common/user-actions';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Spinner } from 'reactstrap';


function Login() {
    Login.displayName = Login.name;

    const userActions = useUserActions();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();


    useEffect(() => {
        if (userActions.isLoggedIn()) {
            window.location.href = "/home"
        }
    }, []);


    require("./Login.css");

    return (
        <div className="login-container">
            <div className="login-image"></div>
            <div className="login-action-container">
                <div className="login-header">
                    <span className="login-preHeader">Welcome to<br /></span>
                    <h1>City Explorer</h1>
                </div>
                <div className={`login-panel ${loading ? "loading" : ""}`}>
                    <GoogleOAuthProvider clientId="193488573105-scccci832ghs4t0kcbel78odsgmbrkq3.apps.googleusercontent.com">
                        <GoogleLogin
                            className="login-button"
                            buttonText="Login"
                            onSuccess={credentialResponse => {
                                    setLoading(true);

                                    userActions.login(credentialResponse).then(
                                    // login was successful
                                    () => {
                                        window.location.href = "/home";

                                        setLoading(false);
                                    },
                                    // login failed
                                    () => {
                                        setError('An unknown error happened. Please try again.');

                                        setLoading(false);

                                        // hide error message after 3 seconds
                                        setTimeout(() => {
                                            setError(null);
                                        }, 3000);
                                    });
                                }
                            }
                            onError={() => {
                                    setError('Could not login user. Please try again.');

                                    setTimeout(() => {
                                        setError(null);
                                    }, 3000);
                                }
                            }
                        />
                    </GoogleOAuthProvider>

                    {error &&
                        <div className='login-error'>
                            {error}
                        </div>
                    }
                    {loading &&
                        <div className='login-loading'>
                            <Spinner animation="grow" variant="dark" /> Loading ...
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Login;