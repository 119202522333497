import React, { useState, useEffect } from 'react';

import { Button } from 'reactstrap';
import './Imprint.css';

import backIcon from '../assets/back.svg';




function Imprint() {
    Imprint.displayName = Imprint.name;

    const back = () => {
        window.history.back();
    };

    return (

        <div>
            <div>
                <h1>About City Explorer</h1>
                <p>
                    This application is created by &copy; Ana-Alina Faustmann.
                    She owns all rights of the application.
                    To read the Terms & Conditions read <a href="/terms-and-conditions">here</a>.
                </p>

                <h1>Sound Effects</h1>
                <ul>
                    <li>Start Sound ... start_mixkit-positive-notification-951.wav</li>
                    <li>Stop Sound ... stopp_mixkit-software-interface-back-2575.mp3</li>
                    <li> Waiting Sound ... notification-140376.mp3 &nbsp;
                        <a href="https://pixabay.com/de/users/universfield-28281460/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=music&amp;utm_content=140376">Universfield</a> from <a href="https://pixabay.com//?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=music&amp;utm_content=140376">Pixabay</a>
                    </li>
                </ul>
            </div>
            <div>
                <Button onClick={back}>
                   Back
                </Button>
            </div>
        </div>
    );
};

export default Imprint;