//https://jasonwatmore.com/post/2021/09/17/react-fetch-set-authorization-header-for-api-requests-if-user-logged-in

//import { useRecoilState } from 'recoil';
//import { authAtom } from '_state';
import {atom} from 'recoil'; 

const authTokenAtom = atom({
    key: 'authToken',
    // get initial state from local storage to enable user to stay logged in
    //localStorage can just handle strings.
    default: localStorage.getItem('authToken')
}); 

export { authTokenAtom };
