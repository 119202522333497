import React, { useRef, useState, useEffect } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { NotificationManager } from 'react-notifications';

function useVoiceCommandUtility() {
    const [commandHandlers, setCommandHandlers] = useState(() => []);
    const [listeningCallback, setListeningCallback] = useState();
    const [transcriptCallback, setTranscriptCallback] = useState();
    const commandHandlersRef = useRef(commandHandlers);

    // - EXPLORER SKIP
    // - EXPLORER RESET POI
    // - EXPLORER x SECONDS BACK - goes x seconds back
    // - EXPLORER LONG VERSION - long description is played
    // - EXPLORER LONG DESCRIPTION - long description is played
    // - EXPLORER SHORT VERSION - short description
    // - EXPLORER SHORT DESCRIPTION - short description

    // - EXPLORER SHOW MEDIAS
    // - EXPLORER PLAY AUDIO FILES

    // - EXPLORER ADD NEW POI
    // - EXPLORER ADD DESCRIPTION

    // - EXPLORER SET RADIUS TO x Meters
    // - EXPLORER SET RADIUS TO ARTEFACT | DETAIL | BUILDING | PLACE | LANDSCAPE
    // - EXPLORER SET IMPACT RADIUM  x Meters
    // - EXPLORER INCREASE SPEECH SPEED
    // - EXPLORER DECREASE SPEECH SPEED
    // - EXPLORER SET VOICE TO ROBO1 | ROBO2 | ..

    const COMMAND_START_EXPLORING = 'EXPLORER START EXPLORING';
    const COMMAND_STOP_EXPLORING = 'EXPLORER STOP EXPLORING'
    const COMMAND_START_EXPLORATION = 'EXPLORER START EXPLORATION';
    const COMMAND_STOP_EXPLORATION= 'EXPLORER STOP EXPLORATION'
    const COMMAND_SKIP = 'EXPLORER SKIP';
    const COMMAND_UNSKIP = 'EXPLORER UNSKIP';
    const COMMAND_RESET_POI = 'EXPLORER RESET POI';
    const COMMAND_AGAIN = 'EXPLORER AGAIN';
    const COMMAND_START = 'EXPLORER START';
    const COMMAND_SET_RANGE = 'EXPLORER SET RANGE *';
    const COMMAND_INCREASE_SPEECH_SPEED = 'EXPLORER INCREASE SPEECH SPEED';
    const COMMAND_DECREASE_SPEECH_SPEED = 'EXPLORER DECREASE SPEECH SPEED';
    const COMMAND_FASTER = "EXPLORER FASTER";
    const COMMAND_SLOWER = "EXPLORER SLOWER";
    const COMMAND_STOP = 'EXPLORER STOP';
    const COMMAND_TITLE = 'COMMAND_TITLE'; 
    const COMMAND_SHORT = 'EXPLORER SHORT';
    const COMMAND_LONG = 'EXPLORER LONG';
    const COMMAND_SHORT_DESCRIPTION = 'EXPLORER SHORT DESCRIPTION';
    const COMMAND_LONG_DESCRIPTION = 'EXPLORER LONG DESCRIPTION';
    const COMMAND_IMAGES = 'EXPLORER IMAGES';

    const commands = [
        {
            //For 'EXPLORER SET RANGE *';
            command: COMMAND_SET_RANGE,
            callback: (range, { resetTranscript }) => {
                console.log(`Range was set to: ${range}`);

                // Clear for next command
                resetTranscript();
            }
        },
        {
            // For Simple commands use own function to handle the command
            command: [COMMAND_START_EXPLORING, COMMAND_STOP_EXPLORING, COMMAND_START_EXPLORATION, COMMAND_STOP_EXPLORATION, COMMAND_SKIP, COMMAND_UNSKIP, COMMAND_RESET_POI, COMMAND_STOP, COMMAND_START,
                COMMAND_AGAIN, COMMAND_STOP],
            callback: (command, spokenPhrase, similarityRatio, { resetTranscript }) => {
                findAndExecuteCommandHandler(command);

                NotificationManager.success(command);
                // Clear for next command
                resetTranscript();
            },
            isFuzzyMatch: true,
            fuzzyMatchingThreshold: 0.8,
            bestMatchOnly: true
        }
        ,
        {
            // For Simple commands use own function to handle the command
            command: [COMMAND_INCREASE_SPEECH_SPEED, COMMAND_DECREASE_SPEECH_SPEED,
                COMMAND_FASTER, COMMAND_SLOWER, COMMAND_TITLE, COMMAND_SHORT, COMMAND_LONG, COMMAND_LONG_DESCRIPTION, COMMAND_SHORT_DESCRIPTION, COMMAND_IMAGES],
            callback: (command, spokenPhrase, similarityRatio, { resetTranscript }) => {
                findAndExecuteCommandHandler(command);

                NotificationManager.success(command);
                // Clear for next command
                resetTranscript();
            },
            isFuzzyMatch: true,
            fuzzyMatchingThreshold: 0.8,
            bestMatchOnly: true
        }
    ]

    // No returen before useEffect.
    // Global: We just need two properties from the object returned by the function
    const { transcript, listening, browserSupportsSpeechRecognition, browserSupportsContinuousListening } = useSpeechRecognition({ commands })

    useEffect(() => {
        //useRef: saves the commandhandlers pointer
        commandHandlersRef.current = commandHandlers;
    }, [commandHandlers]);

    useEffect(() => {
        if (listeningCallback && listeningCallback.callback) {
            listeningCallback.callback(listening);
        }

        if (transcriptCallback && transcriptCallback.callback) {
            transcriptCallback.callback(transcript);
        }

    }, [listening]);

    const findAndExecuteCommandHandler = (command) => {
        // Which command handler should be applied
        const matchedCommandHandlers = commandHandlersRef.current.filter(commandHandler => commandHandler.command === command);
        const matchedCommandHandler = matchedCommandHandlers.length > 0 ? matchedCommandHandlers[0] : null;

        if (matchedCommandHandler) {
            matchedCommandHandler.execute();
        }
    }

    function startTranscript(transcriptCallbackHolder, listeningCallbackHolder) {
        if (!browserSupportsSpeechRecognition) {
            return false;
        }

        setTranscriptCallback(transcriptCallbackHolder);
        setListeningCallback(listeningCallbackHolder);
        SpeechRecognition.startListening({ continuous: false, language: "de-AT" });

        return true;
    }

    function start(continuous, callbackHolder) {
        if (!browserSupportsSpeechRecognition) {
            return false;
        }

        if (continuous) {
            if (!browserSupportsContinuousListening) {
                return null;
            }

            setListeningCallback(callbackHolder);
            SpeechRecognition.startListening({ continuous: true, language: "en-US" });

            return true;
        } else {
            setListeningCallback(callbackHolder);
            SpeechRecognition.startListening({ continuous: false, language: "en-US" });

            return true;
        }
    }

    function stop() {
        SpeechRecognition.stopListening();
    }

    function addCommandHandlers(commandHandlers) {
        setCommandHandlers(commandHandlers);
    }

    // returns an object with functions and constants
    return {
        //Functions 
        start,
        stop,
        startTranscript,
        addCommandHandlers,
        // Constants
        COMMAND_START_EXPLORATION: COMMAND_START_EXPLORATION, 
        COMMAND_START_EXPLORING : COMMAND_START_EXPLORING, 
        COMMAND_STOP_EXPLORATION : COMMAND_STOP_EXPLORATION, 
        COMMAND_STOP_EXPLORING : COMMAND_STOP_EXPLORING,
        COMMAND_SKIP: COMMAND_SKIP,
        COMMAND_RESET_POI: COMMAND_RESET_POI,
        COMMAND_SET_RANGE: COMMAND_SET_RANGE,
        COMMAND_AGAIN: COMMAND_AGAIN,
        COMMAND_START: COMMAND_START,
        COMMAND_UNSKIP: COMMAND_UNSKIP,
        COMMAND_STOP: COMMAND_STOP,
        COMMAND_INCREASE_SPEECH_SPEED: COMMAND_INCREASE_SPEECH_SPEED,
        COMMAND_DECREASE_SPEECH_SPEED: COMMAND_DECREASE_SPEECH_SPEED,
        COMMAND_FASTER: COMMAND_FASTER,
        COMMAND_SLOWER: COMMAND_SLOWER,
        COMMAND_SHORT: COMMAND_SHORT,
        COMMAND_LONG: COMMAND_LONG,
        COMMAND_SHORT_DESCRIPTION: COMMAND_SHORT_DESCRIPTION,
        COMMAND_LONG_DESCRIPTION: COMMAND_LONG_DESCRIPTION, 
        COMMAND_IMAGES: COMMAND_IMAGES,
    };
}


export { useVoiceCommandUtility }
