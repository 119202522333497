import React from 'react';
import { Button } from 'reactstrap';

import backIcon from '../assets/back.svg'; 
import VoiceCommandHelp from './SharedHTML/VoiceCommandHelp';

function Introduction() {
  Introduction.displayName = Introduction.name;

  const back = () => {
    window.history.back();
  };

  return (
    <div className="info-page">
     <a href="javascript:;" onClick={back}>
         <img src={backIcon}></img>
        </a>
      <div>
        <h1>Introduction</h1>

        <h2>Navigation</h2>

        <ul>

          <li>Wander around the city, and you automatically get the information of an POI.   </li>
          <li>First you get the title, then the short descritpion and then the long description  </li>
          <li>You can also get the connected media files to an POI.  </li>
          <li>If you heard an POI once, it should be automatically skipped, as long as you reactivate it or the edit date is newer  </li>
          <li>Use speech control to hear the long version  </li>
          <li>Use speach control to manage the application  </li>
        </ul>

        <h2>Create Content</h2>

        <ul>
          <li>Use Quick Add to create an new POI with your current GPS coordinate. </li>
          <li>You can add or caputre photos from your device.</li>
          <li>You can change the data in the content creation site.</li>
        </ul>

        <VoiceCommandHelp></VoiceCommandHelp>
      </div>
    
    </div>
  );
}

export default Introduction;