import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faLock, faLocationPin, faPlusSquare, faDownload, faLocationDot, faLocationPinLock } from '@fortawesome/free-solid-svg-icons'
import './ContentManager.css';
import { useUserActions } from '../common/user-actions';
import { useMapUtility } from '../common/map-utility';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import PoiTable from './ContentManager/PoiTable';
import PoiMarker from './MapComponents/PoiMarker';

function ContentManager() {
  ContentManager.displayName = ContentManager.name;

  const mapUtility = useMapUtility();
  const userActions = useUserActions();
  const navigate = useNavigate();
  const [currentPosition, setCurrentPosition] = useState();

  const [activeTab, setActiveTab] = useState('1');
  const [mapCenter, setMapCenter] = useState();


  const [pointsOfInterest, setPointsOfInterest] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [nightMode, _] = useState(mapUtility.isNight());

  useEffect(() => {
    const initialize = async () => {
      const position = await mapUtility.getCurrentPosition();

      setCurrentPosition(position);
    }

    initialize();
  }, []);


  useEffect(() => {
    setCurrentUser(userActions.currentUser());
  }, []);



  const handleLoadedMyPublic = (pois) => {
    // if (pois.length > 0) {
    //   const poi = pois[0];
    //   setMapCenter({ lat: poi.latitude, lng: poi.longitude });
    // }

    if (currentPosition != null) {
      setMapCenter({ lat: currentPosition.latitude, lng: currentPosition.longitude });
    }
    setPointsOfInterest(pois);
  };


  const handleLoadedOtherPublic = (pois) => {
    // if (pois.length > 0) {
    //   const poi = pois[0];
    //   setMapCenter({ lat: poi.latitude, lng: poi.longitude });
    // }

    if (currentPosition != null) {
      setMapCenter({ lat: currentPosition.latitude, lng: currentPosition.longitude });
    }
    setPointsOfInterest(pois);
  };


  const handleLoadedPrivate = (pois) => {
    // if (pois.length > 0) {
    //   const poi = pois[0];
    //   setMapCenter({ lat: poi.latitude, lng: poi.longitude });
    // }

    if (currentPosition != null) {
      setMapCenter({ lat: currentPosition.latitude, lng: currentPosition.longitude });
    }
    setPointsOfInterest(pois);
  };

  const selectedTab = (tabId) => {
    setPointsOfInterest([]);
    setActiveTab(tabId);
  }

  const downloadPrivatePointsOfInterests = () => {

    confirmAlert({
      title: 'Confirm to download priavate POIs',
      message: 'Are you sure you want to download all private points?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {

            if (pointsOfInterest != null && pointsOfInterest.length > 0) { 
              NotificationManager.success('You will get an pdf with the detail of ' + pointsOfInterest.length + ' POIs');;
            }
            else 
            {
              NotificationManager.error('You have no private POIs that could be downloaded.');
            }
          }
        },
        {
          label: 'No',
          onClick: () => {
            NotificationManager.info('Report aborted');
          }
        }
      ]
    });
  }

  const renderPointsOfInterestTable = () => {
    return (
      <div>
        {mapCenter && mapCenter.lat !== 0 && mapCenter.lng !== 0 &&
          <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyDjXPpOXmShEoP2OblhU4maG84QhhE1L-M" }}
              defaultCenter={{
                lat: mapCenter?.lat,
                lng: mapCenter?.lng
              }}
              options={mapUtility.getMapOptions}
              defaultZoom={16}
            >
              {
                pointsOfInterest.map(pointOfInterest =>

                  <PoiMarker
                    key={pointOfInterest.id}
                    lat={pointOfInterest.latitude}
                    lng={pointOfInterest.longitude}
                    text={pointOfInterest.title}
                    nightMode={nightMode}
                    poiId={pointOfInterest.id}
                    own={pointOfInterest.authorId === currentUser.id}
                    visited={pointOfInterest.visited}
                    skipped={pointOfInterest.skipped}
                    privacy={pointOfInterest.privacy}
                  />
                )
              }
            </GoogleMapReact>
          </div>
        }

        <br />
        <div className='content-manager'>
          <Nav tabs className='content-manager-nav'>
            <NavItem>
              <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => selectedTab('1')}>
                <FontAwesomeIcon icon={faLocationPin} /> All other public POIs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => selectedTab('2')}>
                <FontAwesomeIcon icon={faLocationDot} />  My public POIs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == '3' ? 'active' : ''} onClick={() => selectedTab('3')}>
                <FontAwesomeIcon icon={faLocationPinLock} /> My private POIs
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {activeTab === "1" &&
                <div>
                  <div className='poi-table-title'>
                    <h3 >All other public POIs</h3>
                  </div>
                  <PoiTable fetchDataUrl="/pointofinterest/find-other-public-points-of-interest" loadedCallback={handleLoadedOtherPublic}>
                  </PoiTable>
                </div>
              }
            </TabPane>
            <TabPane tabId="2">
              {activeTab === "2" &&
                <div>
                  <div className='poi-table-title'>
                    <h3 >My public POIs</h3>
                  </div>
                  <PoiTable fetchDataUrl="/pointofinterest/find-my-public-points-of-interest" loadedCallback={handleLoadedMyPublic}>
                  </PoiTable>

                  <div className='content-manager-add-button'>
                    <button className='btn btn-secondary' onClick={() => navigate("/quick-add")}> <FontAwesomeIcon icon={faPlusSquare} /> Add a public POI </button>
                  </div>


                </div>
              }
            </TabPane>
            <TabPane tabId="3">
              {activeTab === "3" &&
                <div>
                  <div className='poi-table-title'>
                    <h3>My private POIs</h3>
                  </div>
                  <PoiTable fetchDataUrl="/pointofinterest/find-my-private-points-of-interest" loadedCallback={handleLoadedPrivate}>
                  </PoiTable>
               
                  <div className="content-manager-add-button">
                    <button className='btn btn-secondary' onClick={() => navigate("/quick-add")}> <FontAwesomeIcon icon={faPlusSquare} /> Add private POI</button>

                  </div>
                  <br /><br /><br />
                  <div className="content-manager-add-button">
                    <div>
                      <h3>Download private POIs</h3>
                      <button className='btn btn-secondary' onClick={downloadPrivatePointsOfInterests}> <FontAwesomeIcon icon={faDownload} /> Download my journey </button>
                      <br />

                      <span>Download a summary of your private POIs as a HTML, PDF, ... </span>
                    </div>

                  </div>
                </div>

              }
            </TabPane>
          </TabContent>
        </div>
      </div >
    );
  }
  let contents = false
    ? <p><em>Loading...</em></p>
    : renderPointsOfInterestTable();

  return (
    <div>
      <h1>City Explorer Content</h1>

      {contents}
    </div>
  );
}


export default ContentManager;