import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Container, Row, Col, UncontrolledTooltip, Table } from 'reactstrap'
import { useUserActions } from '../common/user-actions';
import { useMapUtility } from '../common/map-utility';
import { useAuthenticatedFetchApi } from '../common/authenticated-fetch-api';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faLocationPin, faLocationPinLock, faCircleDot, faPerson, faFilter } from '@fortawesome/free-solid-svg-icons'
import { NotificationManager } from 'react-notifications';
import { useDebouncedCallback } from 'use-debounce';
import UserMarker from './MapComponents/UserMarker';
import PoiMarker from './MapComponents/PoiMarker';
import './Neighbourhood.css';
import PointOfInterestIcon from './MapComponents/PointOfInterestIcon';

function Neighbourhood() {
    Neighbourhood.displayName = Neighbourhood.name;

    const authenticatedFetchApi = useAuthenticatedFetchApi();
    const userActions = useUserActions();
    const mapUtility = useMapUtility();


    const [pointsOfInterest, setPointsOfInterest] = useState([]);
    const [loading, setLoading] = useState(true);
    const [nightMode, _] = useState(mapUtility.isNight());

    const [searchRange, setSearchRange] = useState(500);
    const [searchRangeMin, setSearchRangeMin] = useState(500);
    const [searchRangeMax, setSearchRangeMax] = useState(500);

    const [visibleSearchRange, setVisibleSearchRange] = useState(500);

    const [circle, setCircle] = useState();

    // User home position
    const [currentUser, setCurrentUser] = useState();

    // Current position on the earth
    const [currentPosition, setCurrentPosition] = useState();

    // Position of neighbourhoodMarker
    const [neighbourhoodPosition, setNeighbourhoodPosition] = useState();



    // Initial map center
    const [mapCenter, setMapCenter] = useState();

    // Is called when neighbourhoodPosition or searchrange is changed
    useEffect(() => {
        populatePointsOfInterestData();
        setSearchRangeMin(10);
        setSearchRangeMax(1000);
    }, [neighbourhoodPosition, searchRange]); // depency-array

    // Init
    useEffect(() => {
        setCurrentUser(userActions.currentUser());
    }, []);

    // Init setcurrent position
    useEffect(() => {
        const initCurrentPosition = async () => {
            mapUtility.getCurrentPosition().then((position) => {
                setCurrentPosition(position);
            }, (error) => {
                NotificationManager.error(error);
            })
        }

        initCurrentPosition();
    }, []);


    // Is called when currentPosition or currentuser is changed.
    useEffect(() => {
        if (currentUser && currentUser.homePositionLatitude !== 0 && currentUser.homePositionLongitude !== 0) {
            const position = { lat: currentUser.homePositionLatitude, lng: currentUser.homePositionLongitude };

            setMapCenter(position);
            setNeighbourhoodPosition(position);
        } else if (currentPosition) {
            const position = { lat: currentPosition.latitude, lng: currentPosition.longitude };

            setMapCenter(position);
            setNeighbourhoodPosition(position);
        }
    }, [currentPosition, currentUser]);


    const populatePointsOfInterestData = async () => {
        if (neighbourhoodPosition) {
            const neighbourhoodPointsOfInterest = await authenticatedFetchApi.get(`/pointofinterest/point-of-interests-for-neighbourhood?searchRange=${searchRange}&lat=${neighbourhoodPosition.lat}&lng=${neighbourhoodPosition.lng}`);

            setPointsOfInterest(neighbourhoodPointsOfInterest);
            setLoading(false);
        }
    }



    const handleApiLoaded = (map, maps) => {
        // Draws the radius circle in google maps
        // map = map is the instance of the google react map
        // maps = container with all drawables like streetmaps, polygons, rectangle, points, ...
        setCircle(new maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.3,
            map,
            center: { lat: neighbourhoodPosition.lat, lng: neighbourhoodPosition.lng },
            radius: searchRange,
        }));
    }

    // TODO: Build a icon component to share this logic
    const getPointOfInterestClass = (pointOfInterest) => {
        if (pointOfInterest.visited) {
            return "poi visited";
        }
        else if (pointOfInterest.skipped) {
            return "poi skipped";
        } else if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 0) {
            // Own public poi
            return "poi own public";
        } else if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 1) {
            // Own private poi
            return "poi own private";
        } else {
            return "poi other public";
        }
    };


    const setMarkerPosition = (event) => {
        setNeighbourhoodPosition({ lat: event.lat, lng: event.lng });

        circle.setCenter({ lat: event.lat, lng: event.lng });
    }

    const handleSearchRangeChange = (event) => {
        const radius = parseInt(event.target.value);
        const searchRangeMin = parseInt(event.target.min);
        const searchRangeMax = parseInt(event.target.max);


        setVisibleSearchRange(radius);
        setSearchRangeMin(searchRangeMin);
        setSearchRangeMax(searchRangeMax);

        circle.setRadius(radius);

        handleDebouncedSearchRangeChange(radius)
    }


    const handleDebouncedSearchRangeChange = useDebouncedCallback(
        // Function
        (radius) => {
            setSearchRange(radius);
        },
        // Delay in ms
        500
    );

    const [filterClasses, setFilterClasses] = useState([]);

    const toggleFilter = (filterClassName) => {
        if (filterClasses.indexOf(filterClassName) >= 0) {
            setFilterClasses(filterClasses.filter(filterClass => filterClass !== filterClassName));
        } else {
            setFilterClasses([...filterClasses, filterClassName]);
        }
    }

    const renderPointsOfInterestTable = () => {
        return (

            <div>

                <div>
                    {mapCenter &&
                        <div className='neighbourhood-container'>
                            <div className={'neigbourhood-filter ' + filterClasses.join(" ")}>
                                <a className="poi-filter-icon"><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon></a>
                                <a id="info-hide-other-public" onClick={() => toggleFilter("hide-other-public")} className={(nightMode ? "night-mode poi other public" : "day-mode poi other public")} > <FontAwesomeIcon icon={faLocationPin} /></a>
                                <UncontrolledTooltip
                                    placement="top"
                                    target="info-hide-other-public"
                                    trigger="click">Other public POIs hidden
                                </UncontrolledTooltip>
                                <a id="info-hide-own-public" onClick={() => toggleFilter("hide-own-public")} className="poi own public"> <FontAwesomeIcon icon={faLocationDot} /></a>
                                <UncontrolledTooltip
                                    placement="top"
                                    target="info-hide-own-public"
                                    trigger="click">Own private POIs hidden
                                </UncontrolledTooltip>
                                <a id="info-hide-own-private" onClick={() => toggleFilter("hide-own-private")} className="poi own private"> <FontAwesomeIcon icon={faLocationPinLock} /></a>
                                <UncontrolledTooltip
                                    placement="top"
                                    target="info-hide-own-private"
                                    trigger="click">Own private POIs hidden
                                </UncontrolledTooltip>
                                <a id="info-hide-visited" onClick={() => toggleFilter("hide-visited-skipped")} className="poi visited"> <FontAwesomeIcon icon={faLocationDot} /></a>
                                <UncontrolledTooltip
                                    placement="top"
                                    target="info-hide-visited"
                                    trigger="click">Visited/skipped POIs hidden
                                </UncontrolledTooltip>
                            </div>
                            <div className={filterClasses.join(" ")} style={{ height: '50vh', width: '100%' }}>
                                <GoogleMapReact
                                    onClick={setMarkerPosition}
                                    bootstrapURLKeys={{ key: "AIzaSyDjXPpOXmShEoP2OblhU4maG84QhhE1L-M" }}
                                    defaultCenter={
                                        { lat: mapCenter.lat, lng: mapCenter.lng }
                                    }
                                    options={mapUtility.getMapOptions}
                                    defaultZoom={16}
                                    yesIWantToUseGoogleMapApiInternals={true}
                                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                >
                                    {pointsOfInterest.map(pointOfInterest =>
                                        <PoiMarker
                                            key={pointOfInterest.id}
                                            lat={pointOfInterest.latitude}
                                            lng={pointOfInterest.longitude}
                                            text={pointOfInterest.title}
                                            nightMode={nightMode}
                                            poiId={pointOfInterest.id}
                                            own={pointOfInterest.authorId === currentUser.id}
                                            visited={pointOfInterest.visited}
                                            skipped={pointOfInterest.skipped}
                                            privacy={pointOfInterest.privacy}
                                        />
                                    )}

                                    {currentUser && <UserMarker
                                        lat={neighbourhoodPosition.lat}
                                        lng={neighbourhoodPosition.lng} />}
                                </GoogleMapReact>
                            </div>
                        </div>
                    }
                </div>

                <div className={filterClasses.join(" ")}>
                    <h3>POIs in your neighbourhood</h3>
                    <Table className='table table-borderless' >
                        <tbody>
                            {pointsOfInterest.map(pointOfInterest =>
                                <tr key={pointOfInterest.id} className={getPointOfInterestClass(pointOfInterest)}>
                                    <td>
                                        <PointOfInterestIcon pointOfInterest={pointOfInterest}></PointOfInterestIcon>
                                    </td>
                                    <td>{pointOfInterest.distance}m</td>
                                    <td>{pointOfInterest.title}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }



    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderPointsOfInterestTable();
    return (
        <div>
            <h1 id="tableLabel" >Neighbourhood</h1>
            {neighbourhoodPosition &&
                <div>
                    <Form>
                        <FormGroup row>
                            <Label for="exampleEmail" xs={2}>
                                <FontAwesomeIcon className="userCurrentPositionIcon" icon={faPerson} />
                                Current Location:
                            </Label>
                            <Col xs={10}>
                                <span style={{ lineHeight: 38 + 'px' }}>
                                    {neighbourhoodPosition.lat}, {neighbourhoodPosition.lng}
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Container>
                                <Row>
                                    <Col xs={2}>
                                        <Label for="searchRange">
                                            <FontAwesomeIcon className="userCurrentPositionIcon" icon={faCircleDot} />
                                            Range:
                                        </Label>
                                    </Col>
                                    <Col xs={10}>
                                        <Input
                                            id="searchRange"
                                            name="range"
                                            type="range"
                                            min={searchRangeMin}
                                            max={searchRangeMax}
                                            value={visibleSearchRange}
                                            onChange={handleSearchRangeChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={2}></Col>
                                    <Col xs={2} className='text-left'>{searchRangeMin}m</Col>
                                    <Col xs={6} className='text-center'>{searchRange}m</Col>
                                    <Col xs={2} className='text-right'>{searchRangeMax}m</Col>
                                </Row>
                            </Container>
                        </FormGroup>
                    </Form>
                </div>
            }
            {contents}
        </div>
    );
}
export default Neighbourhood;