import React, { useEffect, useState } from 'react';
import { Button, Container, Input, Row, Col } from 'reactstrap';
import { useUserActions } from '../common/user-actions';
import { googleLogout } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faHeart, faCertificate, faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { useAuthenticatedFetchApi } from '../common/authenticated-fetch-api';
import { NotificationManager } from 'react-notifications';
import "./Profile.css";

function Profile() {
    Profile.displayName = Profile.name;

    const [currentProfile, setCurrentProfile] = useState();
    const [busy, setBusy] = useState(false);
    const userActions = useUserActions();
    const authenticatedFetchApi = useAuthenticatedFetchApi();

    useEffect(() => {

        // get profile has the profile view with distancewalk etc.
        authenticatedFetchApi.get("/auth/profile").then(response => {
            setCurrentProfile(response);
        });
    }, []);

    const logout = () => {
        setBusy(true);

        try {
            googleLogout();
        } catch (logoutError) {
            console.error(logoutError);
        }
        userActions.logout();
        window.location.href = "/";

        setBusy(false);
    };

    const handleDropDown = (event) => {
        //(parseFloat(event.target.value));
    }

    const handleInactivityText = (event) => {
        //setTitle(event.target.value);
    };

    const resetAllPointsOfInterest = async () => {
        try {
            setBusy(true);
            await authenticatedFetchApi.post("/pointofinterest/reset-all-points-of-interest");

            setBusy(false);
            NotificationManager.success('Saved');
        } catch (error) {
            NotificationManager.error(error);
        }
    }

    return (
        <div>
            {currentProfile ?
                (
                    <div className='profile-page'>
                        <div className='profile-header'>
                            <div>
                                <div className='profile-button-container'>
                                    <Button color="" onClick={logout} disabled={busy}>
                                        <FontAwesomeIcon icon={faArrowRightFromBracket} />
                                    </Button>
                                </div>
                                
                                <div className="profile-image-container">
                                    <img src={`/picture/profile-picture/${currentProfile.user.id}/big`} alt="Avatar" style={{ borderRadius: '50%', width: '250px' }} />
                                    {currentProfile.badge && <FontAwesomeIcon icon={faCertificate} className="color-badge profile-badge" />}
                                </div>


                            </div>

                            <h1>Hello {currentProfile.user.name.substring(0, currentProfile.user.name.indexOf(" "))}!</h1>
                        </div>
                        <p>
                            With your account you can explore the city and add entries.
                        </p>

                        <div>
                            <h3>Statistics</h3>
                            <p>You have {currentProfile.points} points and are in level {currentProfile.level}. </p>
                            {currentProfile.badge ?
                                <p>You have a badge. <FontAwesomeIcon icon={faCertificate} className="color-badge" /> </p>
                                :
                                <p>You don't have a badge yet. <FontAwesomeIcon icon={faCertificate} className="color-badge" /></p>
                            }
                            <p>You already have {currentProfile.distanceWalked}km and {currentProfile.poiVisitCount} POIs visited.</p>
                            <p>You created {currentProfile.poiCreatedCount} POIs by your own and annotated {currentProfile.poiAnnotationsCount} POIs from others.</p>
                            <p>You got {currentProfile.likesGot} <FontAwesomeIcon icon={faHeart} className="color-heart" /> from others.</p>
                        </div>


                        <h3>Terms and Conditions</h3>
                        <a href="/terms-and-conditions">Show me the terms and conditions</a>


                        <h3>Newsletter</h3>

                        <div>I want
                            <Input className='newsletter-dropdown' name="select" type="select" value={0} onChange={handleDropDown} >
                                <option value="0">weekly</option>
                                <option value="1">monthly</option>
                                <option value="2">daily</option>
                                <option value="3">never</option>
                            </Input> newsletter about new POIs
                        </div>

                        <div>I want
                            <Input className='newsletter-dropdown' name="select" type="select" value={0} onChange={handleDropDown} >
                                <option value="0">weekly</option>
                                <option value="1">monthly</option>
                                <option value="2">daily</option>
                                <option value="3">never</option>
                            </Input> newsletters about changed POIs
                        </div>

                        <div>I want
                            <Input className='newsletter-dropdown' name="select" type="select" value={0} onChange={handleDropDown} >
                                <option value="0">weekly</option>
                                <option value="1">monthly</option>
                                <option value="2">daily</option>
                                <option value="3">never</option>
                            </Input> reports about my success
                        </div>

                        <div>I want
                            <Input className='newsletter-dropdown' name="select" type="select" value={0} onChange={handleDropDown}>
                                <option value="0">weekly</option>
                                <option value="1">monthly</option>
                                <option value="2">daily</option>
                                <option value="3">never</option>
                            </Input> reminder, if an author I follow, published something
                        </div>

                        <div>I want
                            <Input className='newsletter-dropdown' name="select" type="select" value={0} onChange={handleDropDown}>
                                <option value="0">weekly</option>
                                <option value="1">monthly</option>
                                <option value="2">daily</option>
                                <option value="3">never</option>
                            </Input> reminder, if I am inactive for

                            <Input className='newsletter-inactivity-text' placeholder="Inactivity duration" value="2" onChange={handleInactivityText} type="text" />

                            <Input className='newsletter-dropdown' name="select" type="select" value={0} onChange={handleDropDown}>
                                <option value="0">weeks</option>
                                <option value="1">months</option>
                                <option value="2">days</option>
                            </Input>
                        </div>
                        <h3>Reset all POIs</h3>
                        <div>
                            <p>
                                Click here to reset all point of interest to unheard.
                                <br />  <br />
                                <Button className='profile-reset-all-pois' color="primary" onClick={resetAllPointsOfInterest} disabled={busy}>
                                    <FontAwesomeIcon icon={faArrowRotateLeft} /> Reset all points of interest
                                </Button>
                            </p>
                        </div>

                        <h3>Imprint</h3>
                        <a href="/imprint">Show me the imprint</a>
                    </div>

                ) :
                (
                    <div>
                        <p>Loading user profile ...</p>

                        <h3>Terms and Conditions</h3>
                        <a href="/terms-and-conditions">Show me the terms and conditions</a>

                        <h3>Imprint</h3>
                        <a href="/imprint">Show me the imprint</a>
                    </div>
                )
            }
        </div>
    );

}

export default Profile;