import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPerson } from '@fortawesome/free-solid-svg-icons'

function HomePositionMarker() {
    HomePositionMarker.displayName = HomePositionMarker.name;
    return (
        <span className="homePositionMarker"><FontAwesomeIcon icon={faPerson} className="homePositionMarkerIcon" /></span>
    );
}

export default HomePositionMarker;