import React, { useState, useEffect } from 'react';
import { useAuthenticatedFetchApi } from '../common/authenticated-fetch-api';
import { Gallery } from "react-grid-gallery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import PointOfInterestModal from "./SharedHTML/PointOfInterestModal";
import './Home.css';

// rewrite to functional components instead of class components
function Home() {
  const authenticatedFetchApi = useAuthenticatedFetchApi();
  const [carouselImages, setCarouselImages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedPoi, setSelectedPoi] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  Home.displayName = Home.name;

  useEffect(() => {
    const fetchData = async () => {
      await loadImages("/pointofinterest/point-of-interests-for-home/5/true", images => setCarouselImages(images));
      await loadImages("/pointofinterest/point-of-interests-for-home/25/false", images => setGalleryImages(images));
    }

    fetchData().catch(console.error);
  }, []);

  const loadImages = async (url, setPropertyCallback) => {
    const pointOfInterests = await authenticatedFetchApi.get(url);

    const transformedImages = pointOfInterests.map(pointOfInterest => {
      // get main picture (first image)
      const picture = pointOfInterest.pictures[0];

      const transformedImage = {
        src: `/picture/${picture.id}/300`,
        highResolutionSrc: `/picture/${picture.id}/800`,
        width: picture.width,
        height: picture.height,
        caption: pointOfInterest.title,
        author: pointOfInterest.author,
        pointOfInterest: pointOfInterest
      };

      return transformedImage;
    });

    // setting the images for the plugin
    setPropertyCallback(transformedImages);
  }

  const selectPoi = (poi) => {
    setSelectedPoi(poi);
    setIsModalOpen(true);
  };

  return (
    <div>
      <h1>Home</h1>

      <div>
        <h3>What's new in your neighbourhood</h3>

        {carouselImages && carouselImages.length > 0 &&
          <Carousel showThumbs={false} onClickItem={(itemIndex, _) => selectPoi(carouselImages[itemIndex].pointOfInterest)}>
            {carouselImages.map((image, index) => <div key={index}>
              <img style={{ height: "300px" }} src={image.highResolutionSrc} alt={image.caption} />
              {image.author ?
                <p className="legend customized">
                  <img src={`/picture/profile-picture/${image.author.id}/default`} alt={image.author.name} style={{ borderRadius: '50%', width: '30px' }} />
                  &nbsp;
                  {image.author.name}
                </p> :
                <p className="legend customized">
                  System
                </p>
              }
            </div>)}
          </Carousel>
        }

        {galleryImages && galleryImages.length > 0 &&
          <>
            <h3>Browse all</h3>
            <Gallery images={galleryImages} onSelect={(index) => selectPoi(galleryImages[index].pointOfInterest)} />
          </>
        }
      </div>

      <PointOfInterestModal isOpen={isModalOpen} pointOfInterest={selectedPoi} selectedPictureIndex={0} onClosed={() => { setIsModalOpen(false); }}>
      </PointOfInterestModal>
    </div>
  );
}

export default Home;
