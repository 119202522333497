import { Table } from 'reactstrap'

function VoiceCommandHelp() {
    VoiceCommandHelp.displayName = VoiceCommandHelp.name;
    return (

        <div>
            <h2>Voice Commands</h2>
            <p>Voice commands are just active during an exploration.<br /> If the commands don't work, click the [start listening] button to reactivate the speech recognition</p>
            <p>The following voice commands are possible: </p>
            <Table>
                <thead>
                    <tr><th>COMMAND</th><th>DESCRIPTION</th></tr>
                </thead>
                <tbody>
                    <tr>
                        <td>EXPLORER START EXPLORATION or EXPLORER START EXPLORING </td>
                        <td>Starts an exploration.</td>
                        </tr>
                    <tr>
                        <td>EXPLORER STOP EXPLORATION or EXPLORER STOP EXPLORING </td>
                        <td>Stops an exploration.</td>
                        </tr>
                    <tr><td>EXPLORER STOP </td>
                    <td> Stops the speech synthesis.</td>
                    </tr>
                    <tr><td>EXPLORER SKIP </td>
                    <td>Skipps the POI </td>
                    </tr>
                    <tr><td>EXPLORER UNSKIP or EXPLORER RESET POI </td>
                    <td>Unskips the POI </td></tr>
                    <tr><td>EXPLORER AGAIN or START </td>
                    <td>Resets the POI to unheard</td>
                    </tr>
                    <tr><td>EXPLORER INCREASE SPEECH SPEED or EXPLORER FASTER</td>
                    <td>Increases the speech speed in the speech synthesis for the next POI</td></tr>
                    <tr><td>EXPLORER DECREASE SPEECH SPEED or EXPLORER SLOWER </td>
                    <td>Decreases the speech speed in the speech synthesis for the next POI</td></tr>
                    <tr><td>EXPLORER TITLE</td><td>Reads the title of the POI</td></tr>
                    <tr><td>EXPLORER SHORT or EXPLORER SHORT DESCRIPTON</td>
                    <td>Reads the short description of the POI</td></tr>
                    <tr><td>EXPLORER LONG or EXPLORER LONG DESCRIPTON</td><td>Reads the long description of the POI</td></tr>
                    <tr><td>EXPLORER IMAGES</td><td>Reads the image titles, descriptions, references of the POI and opens the gallery</td></tr>
                </tbody>
            </Table>

        </div>
    );
}

export default VoiceCommandHelp;