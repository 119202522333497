import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { Form, FormGroup, Label, Input, FormText, Container, Row, Col, UncontrolledTooltip, InputGroup, Button } from 'reactstrap'
import GoogleMapReact from 'google-map-react';

import { useUserActions } from '../common/user-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faInfo, faBug, faComment, faComments, faCheck, faInfoCircle, faMicrophone, faMicrophoneSlash, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useAuthenticatedFetchApi } from '../common/authenticated-fetch-api';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useRecoilState } from 'recoil';
import { useMapUtility } from '../common/map-utility';
import { useVoiceCommandUtility } from '../common/voice-command-utility';
import { authTokenAtom } from '../common/auth-token-atoms';
import UserMarker from './MapComponents/UserMarker';
import PoiMarker from './MapComponents/PoiMarker';
import backIcon from '../assets/back.svg';
import "./QuickAdd.css"



function QuickAdd() {
  QuickAdd.displayName = QuickAdd.name;

  const back = async (event) => {
    event.preventDefault();

    navigate("/content-manager");
  }

  const userActions = useUserActions();
  const [searchParams, setSearchParams] = useSearchParams();

  const [mode, setMode] = useState("add");
  const [currentUser, setCurrentUser] = useState(userActions.currentUser());
  

  const authenticatedFetchApi = useAuthenticatedFetchApi();
  const mapUtility = useMapUtility();
  const voiceCommandUtility = useVoiceCommandUtility();

  const [nightMode, _] = useState(mapUtility.isNight());
  const [listening, setListening] = useState(false);
  const [currentPropertyTranscript, setCurrentPropertyTranscript] = useState();
  const [authToken, setAuthToken] = useRecoilState(authTokenAtom);

  const [previousVersionPointOfInterest, setPreviousVersionPointOfInterest] = useState();
  const [pointOfInterest, setPointOfInterest] = useState();
  const [currentPosition, setCurrentPosition] = useState();
  const [categories, setCategories] = useState([]);


  const navigate = useNavigate();


  useEffect(() => {
    const loadPointOfInterest = async () => {

      let poiId = searchParams.get("poi-id");

      if (poiId) {
        setMode("edit");
        QuickAdd.displayName = QuickAdd.name;

        let pointOfInterestToEdit = await authenticatedFetchApi.get(`/pointofinterest/get?pointOfInterestId=${poiId}`);

        setPointOfInterest(pointOfInterestToEdit);
        setPreviousVersionPointOfInterest({ ...pointOfInterestToEdit });
      } else {
        let newPointOfInterest = await authenticatedFetchApi.post("/pointofinterest/create-point-of-interest");

        setPointOfInterest(newPointOfInterest);
        setSearchParams({ "poi-id": newPointOfInterest.id });

        // Changing private POIs, Adding an own POI, Annotate other POIs, Changing own POI
      }
    };

    loadPointOfInterest();
  }, []);

  useEffect(() => {
    const initialize = async () => {
      const position = await mapUtility.getCurrentPosition();

      setCurrentPosition(position);

      if (pointOfInterest && pointOfInterest.latitude === 0 && pointOfInterest.longitude === 0) {
        setPointOfInterest({ ...pointOfInterest, latitude: position.latitude, longitude: position.longitude });
      }
    }

    initialize();
  }, [pointOfInterest]);


  useEffect(() => {
    const initialize = async () => {
      const fetchedCategories = await authenticatedFetchApi.get("category");

      setCategories(fetchedCategories);
    };

    initialize();
  }, []);


  const annotatePoi = async () => {
    if (previousVersionPointOfInterest) {
      await authenticatedFetchApi.post('pointofinterest/annotate', { pointOfInterestId: previousVersionPointOfInterest.id });
      NotificationManager.info('Annotation successfull');

      navigate("/content-manager");
    }

  }

  const reportPoi = () => {

    confirmAlert({
      title: 'Confirm to report the POI',
      message: 'Are you sure you want to report the poi',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            NotificationManager.success('POI ' + previousVersionPointOfInterest?.title + 'reported');
          }
        },
        {
          label: 'No',
          onClick: () => {
            NotificationManager.info('Report aborted');
          }
        }
      ]
    });
  }


  const deletePicture = (pointOfInterestId, pictureId) => {


    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete the image.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            let deleteResponse = await authenticatedFetchApi.post("pointofinterest/deletePicture", { "pointOfInterestId": pointOfInterestId, "pictureId": pictureId });

            if (deleteResponse.success) {
              // get all pictures whithout the currently deleted
              setPointOfInterest({
                ...pointOfInterest, pictures: pointOfInterest.pictures.filter((picture, _) => picture.id !== pictureId)
              });

              NotificationManager.success('Image deleted ' + pictureId);
            } else {
              NotificationManager.error('Could not delete picture.');
            }
          }
        },
        {
          label: 'No',
          onClick: () => {
            NotificationManager.info('Aborted');
          }
        }
      ]
    });
  };

  const reportPicture = (pointOfInterestId, picture) => {


    confirmAlert({
      title: 'Confirm to report',
      message: 'Are you sure you want to report the picture with the name ' + picture.fileName,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            NotificationManager.success('Picture reported.');

          }
        },
        {
          label: 'No',
          onClick: () => {
            NotificationManager.info('Aborted');
          }
        }
      ]
    });
  };
  const handleTitleChange = (event) => {
    setPointOfInterest({ ...pointOfInterest, title: event.target.value });
  };

  const handlePrivacyChange = (event) => {
    setPointOfInterest({ ...pointOfInterest, privacy: parseInt(event.target.value, 10) });
  };

  const handleShortDescriptionChange = (event) => {
    setPointOfInterest({ ...pointOfInterest, shortDescription: event.target.value });
  };

  const handleLongDescriptionChange = (event) => {
    setPointOfInterest({ ...pointOfInterest, longDescription: event.target.value });
  };

  const handleReferencesChange = (event) => {
    setPointOfInterest({ ...pointOfInterest, references: event.target.value });
  };

  const handlePublishedChange = (event) => {
    setPointOfInterest({ ...pointOfInterest, published: event.target.checked });
  };

  const handleFeaturedChange = (event) => {
    setPointOfInterest({ ...pointOfInterest, featured: event.target.checked });
  };

  const startTranscript = (property) => {
    setCurrentPropertyTranscript(property);

    voiceCommandUtility.startTranscript(
      {
        callback: (transcript) => {
          const copy = { ...pointOfInterest };

          if (!copy[property]) {
            copy[property] = transcript;
          } else {
            copy[property] += transcript;
          }

          setPointOfInterest(copy);
        }
      },
      {
        callback: (isListening) => {
          setListening(isListening);
        }
      }
    );
  };

  const handlePictureTitleChange = (event, pictureIndex) => {

    setPointOfInterest({
      ...pointOfInterest, pictures: pointOfInterest.pictures.map((picture, index) => {
        if (index === pictureIndex) {
          return { ...picture, title: event.target.value };
        } else {
          return picture;
        }
      })
    });
  }


  const handlePictureDescriptionChange = (event, pictureIndex) => {

    setPointOfInterest({
      ...pointOfInterest, pictures: pointOfInterest.pictures.map((picture, index) => {
        if (index === pictureIndex) {
          return { ...picture, description: event.target.value };
        } else {
          return picture;
        }
      })
    });
  }

  const handlePictureReferenceChange = (event, pictureIndex) => {
    setPointOfInterest({
      ...pointOfInterest, pictures: pointOfInterest.pictures.map((picture, index) => {
        if (index === pictureIndex) {
          return { ...picture, reference: event.target.value };
        } else {
          return picture;
        }
      })
    });
  }

  const handleFileSelected = async (event) => {
    const files = Array.from(event.target.files);

    for (const file of files) {
      const data = new FormData();

      data.append("pointOfInterestId", pointOfInterest.id);
      data.append("picture", file, file.name);

      fetch("/pointofinterest/add-picture-to-point-of-interest", {
        method: 'post',
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        body: data,
      }).then(response => {
        response.json().then(updatedPointOfInterest => {
          if (updatedPointOfInterest) {
            setPointOfInterest({ ...pointOfInterest, pictures: updatedPointOfInterest.pictures });

            NotificationManager.success(`Picture ${file.name} added.`);
          } else {
            NotificationManager.error(`Could not add picture ${file.name}.`);
          }
        });
      }, () => {
        NotificationManager.error(`Could not add picture ${file.name}.`);
      });
    }
  };

  const onSelectCategory = (selectedList, _) => {
    updatePointOfInterestCategories(selectedList);
  };

  const onRemoveCategory = (selectedList, _) => {
    updatePointOfInterestCategories(selectedList);
  };

  const updatePointOfInterestCategories = (categories) => {
    setPointOfInterest({
      ...pointOfInterest,
      pointOfInterestCategories: categories.map(selectedCategory => {
        return {
          categoryId: selectedCategory.id,
          category: selectedCategory,
          pointOfInterestId: pointOfInterest.id,
          pointOfInterest: pointOfInterest
        };
      })
    });
  }


  const save = async (event) => {
    event.preventDefault();

    const updatedPointOfInterest = await authenticatedFetchApi.post("/pointofinterest/update-point-of-interest", pointOfInterest);

    if (updatedPointOfInterest) {
      NotificationManager.success("Saved.");
    } else {
      NotificationManager.error("Could not update POI.");
    }
  }

  const setMarkerPosition = (event) => {
    setPointOfInterest({ ...pointOfInterest, latitude: event.lat, longitude: event.lng });
  };

  const setMarkerPositionHere = async () => {
    const position = await mapUtility.getCurrentPosition();

    setPointOfInterest({ ...pointOfInterest, latitude: position.latitude, longitude: position.longitude });
    setCurrentPosition(position);
  };

  const renderMap = (currentPosition) => {
    return (<div style={{ height: '250px', width: '100%' }}>
      {currentPosition &&
        <GoogleMapReact
          onClick={setMarkerPosition}
          bootstrapURLKeys={{ key: "AIzaSyDjXPpOXmShEoP2OblhU4maG84QhhE1L-M" }}
          center={{
            lat: currentPosition?.latitude,
            lng: currentPosition?.longitude
          }}
          options={mapUtility.getMapOptions}
          defaultZoom={11}
        >
          {pointOfInterest && <PoiMarker
            key="poiMarker"
            lat={pointOfInterest.latitude}
            lng={pointOfInterest.longitude}
            nightMode={nightMode}
            poiId={pointOfInterest.id}
            own={pointOfInterest.author?.id === currentUser?.id}
            visited={false}
            skipped={false}
            privacy={pointOfInterest.privacy} />}

          {currentPosition && <UserMarker
            key="userMarker"
            lat={currentPosition?.latitude}
            lng={currentPosition?.longitude} />
          }
        </GoogleMapReact>
      }
    </div>);
  }

  const render = () => {
    const map = renderMap(currentPosition);

    return (

      <div>
        {
          previousVersionPointOfInterest && previousVersionPointOfInterest.author?.id !== currentUser?.id &&
          <h1>Content Manager - Annotate others POI</h1>
        }

        {
          pointOfInterest && pointOfInterest.author?.id === currentUser?.id && mode == "edit" &&
          <h1 >Content Manager -  Changing own POI</h1>
        }

        {
          pointOfInterest && pointOfInterest.author?.id === currentUser?.id && mode == "add" &&

          <h1 >Content Manager -  Adding own POI</h1>
        }
        <Form>

          <FormGroup>
            {map}
            <FormText>
              Place the marker by clicking on the desired location or click the button below to set it to the current position *
            </FormText>
            <br />
            <Button onClick={setMarkerPositionHere}>
              <FontAwesomeIcon icon={faLocationDot} /> Set to here!
            </Button>
          </FormGroup>

          <FormGroup>
            <Container>
              <Row>
                <Col xs="2">
                  <Label>
                    <UserMarker></UserMarker><span>My Location *</span>
                  </Label>
                </Col>
                <Col xs="10" style={{ "padding": "17px" }}>
                  {currentPosition && currentPosition.latitude != null && currentPosition.longitude != null && currentPosition.latitude != undefined && currentPosition.longitude != undefined ?
                    <FormText>{currentPosition.latitude}, {currentPosition.longitude} (WGS84  EPSG Code 3857)</FormText>
                    :
                    <FormText>No position found, please click on the map.</FormText>
                  }
                </Col>
              </Row>
              <Row>
                <Col xs="2">
                  <Label>
                    <FontAwesomeIcon className="poi-position-marker-icon" icon={faLocationDot}></FontAwesomeIcon><span>POI Location *</span>
                  </Label>
                </Col>
                <Col xs="10" style={{ "padding": "14px" }}>{pointOfInterest && pointOfInterest.latitude != null && pointOfInterest.longitude != null && pointOfInterest.latitude != undefined && pointOfInterest.longitude != undefined ?
                  <FormText>{pointOfInterest.latitude}, {pointOfInterest.longitude} (WGS84  EPSG Code 3857)</FormText>
                  :
                  <FormText></FormText>
                }</Col>
              </Row>
            </Container>
          </FormGroup>

          <FormGroup>
            <Label for="poiTitle">
              Title *
            </Label>

            {
              previousVersionPointOfInterest && previousVersionPointOfInterest.author?.id !== currentUser?.id &&
              <div className='quick-add-owner-data'>{previousVersionPointOfInterest?.title}
              </div>
            }

            <InputGroup>
              <Input
                id="poiTitle"
                name="title"
                placeholder="Name of POI"
                value={pointOfInterest?.title || ""}
                onChange={handleTitleChange}
                type="text"
              />
              <Button onClick={() => startTranscript("title")}>
                {listening && currentPropertyTranscript === "title"
                  ? <FontAwesomeIcon icon={faMicrophone} />
                  : <FontAwesomeIcon icon={faMicrophoneSlash} />
                }

              </Button>
            </InputGroup>


          </FormGroup>
          {pointOfInterest && pointOfInterest.author?.id === currentUser?.id &&
            <FormGroup>

              <Label for="poiPrivacy">
                Privacy * <span id="infoPrivacy"><FontAwesomeIcon icon={faInfoCircle} className="infoIcon" /></span>
                <UncontrolledTooltip
                  placement="top"
                  target="infoPrivacy"
                  trigger="click">
                  Public Points of Interest are available for all users. Private POIs are just visible for you and downloadable in the content manager.
                </UncontrolledTooltip>
              </Label>

              <Input id="poiPrivacy" name="select" type="select" value={pointOfInterest?.privacy || 0} onChange={handlePrivacyChange}>
                <option value="0">Public</option>
                <option value="1">Private</option>
              </Input>

            </FormGroup>
          }
          <FormGroup>
            <Label for="poiCategories">
              Categories * <span id="infoCategories"><FontAwesomeIcon icon={faInfoCircle} className="infoIcon" /></span>
              {previousVersionPointOfInterest && previousVersionPointOfInterest.author?.id !== currentUser?.id &&
                <div className='quick-add-owner-data'>{(previousVersionPointOfInterest?.pointOfInterestCategories || []).map(pointOfInterestCategory => pointOfInterestCategory.category.name).join(",")}</div>}
              <UncontrolledTooltip
                placement="top"
                target="infoCategories"
                trigger="click">
                A POI can have multiple categories. This categories are used to filter your desired POIs in the exploaration mode.
              </UncontrolledTooltip>
            </Label>
            <Multiselect
              options={categories}
              selectedValues={pointOfInterest?.pointOfInterestCategories?.map(pointOfInterestCategory => pointOfInterestCategory.category) || []}
              onSelect={onSelectCategory}
              onRemove={onRemoveCategory}
              displayValue="name"
            />
          </FormGroup>


          <FormGroup>
            <Label for="poiShortDescription">
              Short Description <span id="infoShortDescription"><FontAwesomeIcon icon={faInfoCircle} className="infoIcon" /></span>
              {
                previousVersionPointOfInterest && previousVersionPointOfInterest.author?.id !== currentUser?.id &&
                <div className='quick-add-owner-data'>{previousVersionPointOfInterest?.shortDescription ? previousVersionPointOfInterest?.shortDescription : "There was no short description."}
                </div>
              }
              <UncontrolledTooltip
                placement="top"
                target="infoShortDescription"
                trigger="click">
                The short description of an point of interest should contain the main facts of an building, monument and so on.
                <br /> Use your microphone to use the speech recognition.
              </UncontrolledTooltip>
            </Label>
            <InputGroup>
              <Input
                id="poiShortDescription"
                name="shortDescription"
                type="textarea"
                value={pointOfInterest?.shortDescription || ""}
                onChange={handleShortDescriptionChange}
              />
              <Button onClick={() => startTranscript("shortDescription")}>
                {listening && currentPropertyTranscript === "shortDescription"
                  ? <FontAwesomeIcon icon={faMicrophone} />
                  : <FontAwesomeIcon icon={faMicrophoneSlash} />
                }

              </Button>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="poiLongDescription">
              Long Description <span id="infoLongDescription"><FontAwesomeIcon icon={faInfoCircle} className="infoIcon" /></span>
              {
                previousVersionPointOfInterest && previousVersionPointOfInterest.author?.id !== currentUser?.id &&
                <div className='quick-add-owner-data'>{previousVersionPointOfInterest?.longDescription ? previousVersionPointOfInterest?.longDescription : "There was no long description"}
                </div>
              }
              <UncontrolledTooltip
                placement="top"
                target="infoLongDescription"
                trigger="click">
                The long description of a point of interest should contain additional and detailed information. Please make sure that this data does not overlap with the short description.
                <br /> Use your microphone to use the speech recognition.
              </UncontrolledTooltip>
            </Label>
            <InputGroup>
              <Input
                id="poilongDescription"
                name="longDescription"
                type="textarea"
                value={pointOfInterest?.longDescription || ""}
                onChange={handleLongDescriptionChange}
              />
              <Button onClick={() => startTranscript("longDescription")}>
                {listening && currentPropertyTranscript === "longDescription"
                  ? <FontAwesomeIcon icon={faMicrophone} />
                  : <FontAwesomeIcon icon={faMicrophoneSlash} />
                }

              </Button>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="poiReferences">
              References <span id="infoReferences"><FontAwesomeIcon icon={faInfoCircle} className="infoIcon" /></span>
              {
                previousVersionPointOfInterest && previousVersionPointOfInterest.author?.id !== currentUser?.id &&
                <div className='quick-add-owner-data'>{previousVersionPointOfInterest?.references ? previousVersionPointOfInterest?.references : "There was no referebces"}
                </div>
              }
              <UncontrolledTooltip
                placement="top"
                target="infoReferences"
                trigger="click"
              >
                Please add here the references if the data is not from you. You need to write it as detailed as possible by giving for example the title, author, year, page number, published in yournal, ISBN, Website url, accessed date and so on.. <br />
                For example: <br />
                [1] "Neunkirchen für unsere Gäste und Einheimische -
                Kurzführer über de Geschichte und sonstiges Wissenswertes", Wolfgang Haider-Berky
                <br />
                [2] "Apotheke Chronik", https://www.rat-tat.at/chronik_apotheke_neunkirchen.html, zuletzt aufgerufen am 1.10.2022
                <br />
                [3] "Das Schützenhaus Neunkirchen", http://www.sgnk1736.at/", zuletzt aufgerufen am 1.10.2022
              </UncontrolledTooltip>
            </Label>
            <InputGroup>
              <Input
                id="poiReferences"
                name="references"
                type="textarea"
                value={pointOfInterest?.references || ""}
                onChange={handleReferencesChange}
              />
              <Button onClick={() => startTranscript("references")}>
                {listening && currentPropertyTranscript === "references"
                  ? <FontAwesomeIcon icon={faMicrophone} />
                  : <FontAwesomeIcon icon={faMicrophoneSlash} />
                }

              </Button>
            </InputGroup>
          </FormGroup>
          {
            previousVersionPointOfInterest && previousVersionPointOfInterest.author?.id === currentUser?.id &&
            <FormGroup>
              <Label for="poiFeatured">
                Featured <span id="infoFeatured"><FontAwesomeIcon icon={faInfoCircle} className="infoIcon" /></span>
                <UncontrolledTooltip
                  placement="top"
                  target="infoFeatured"
                  trigger="click">
                  If the poi is featured, it will appear on top of the home page.
                </UncontrolledTooltip>
              </Label>
              <br />
              <Input
                id="poiFeatured"
                name="featured"
                type="checkbox"
                checked={pointOfInterest?.featured || false}
                onChange={handleFeaturedChange}
              />
            </FormGroup>
          }
          {
            previousVersionPointOfInterest && previousVersionPointOfInterest.author?.id === currentUser?.id &&
            <FormGroup>
              <Label for="poiPublished">
                Published <span id="infoPublished"><FontAwesomeIcon icon={faInfoCircle} className="infoIcon" /></span>
                <UncontrolledTooltip
                  placement="top"
                  target="infoPublished"
                  trigger="click">
                  If the poi is not published, it will not appear in exploration or neighbourhood.
                </UncontrolledTooltip>
              </Label>
              <br />
              <Input
                id="poiPublished"
                name="published"
                type="checkbox"
                checked={pointOfInterest?.published || false}
                onChange={handlePublishedChange}
              />
            </FormGroup>
          }
          <FormGroup>
            <Label for="poiPictures">
              Pictures
            </Label>
            <Input
              id="poiPictures"
              onChange={handleFileSelected}
              multiple
              name="file"
              type="file"
              accept=".jpg, .png, .jpeg, .gif"
            />
            <FormText>
              Please upload only pictures with a maximum size of less than 1MB.
            </FormText>

            {pointOfInterest && pointOfInterest.pictures && pointOfInterest.pictures.length > 0 &&
              <Container className='upload-images-container'>
                {pointOfInterest.pictures.map((picture, index) =>
                  <Row key={picture.id}>
                    <Col md="3">
                      <div className='upload-image-container'>
                        <img src={`/picture/${picture.id}/300`} width="300px" />


                        <div className='upload-image-attributes'>

                          {pointOfInterest.author?.id === currentUser?.id
                            ? <FontAwesomeIcon className="bug-button delete-button" icon={faTrash} onClick={() => deletePicture(pointOfInterest.id, picture.id)} />
                            : <FontAwesomeIcon className="bug-button" icon={faBug} onClick={() => reportPicture(pointOfInterest.id, picture)} />
                          }

                          <div>
                            {picture.fileName}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="9">
                      <div>
                        <div>
                          <Label for="pictureTitle">
                            Titel
                          </Label>
                          <Input
                            id="pictureTitle"
                            name="pictureTitle"
                            placeholder="Title"
                            value={picture.title}
                            onChange={e => handlePictureTitleChange(e, index)}
                            type="text"
                          />
                        </div>
                        <div>
                          <Label for="pictureDescription">
                            Description
                          </Label>
                          <Input
                            id="pictureDescription"
                            name="pictureDescription"
                            placeholder="Description"
                            value={picture.description}
                            onChange={e => handlePictureDescriptionChange(e, index)}
                            type="text"
                          />
                        </div>
                        <div>
                          <Label for="pictureReference">
                            Reference
                          </Label>
                          <Input
                            id="pictureReference"
                            name="pictureReference"
                            placeholder="Reference"
                            value={picture.reference}
                            onChange={e => handlePictureReferenceChange(e, index)}
                            type="text"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>)
                }
              </Container>
            }
          </FormGroup>
        </Form>

        {pointOfInterest && pointOfInterest.author?.id === currentUser?.id &&
          <div className='quickadd-submit-button'>
            <a className="" href="javascript:;" onClick={back}>
              <img src={backIcon}></img>
            </a>

            <Button onClick={save}><FontAwesomeIcon icon={faCheck} /> Save</Button>

          </div>
        }

        {pointOfInterest && pointOfInterest.author?.id !== currentUser?.id &&
          <div className='quickadd-buttons-annotate'>
            <Button onClick={reportPoi}><FontAwesomeIcon icon={faBug} /> Report POI</Button>
            <a className="btn a-to-button " onClick={annotatePoi}><FontAwesomeIcon icon={faComments} /> Done with commenting</a>
          </div>
        }
      </div>
    );
  }

  return render();
}
export default QuickAdd;