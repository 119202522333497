import React, { useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faSquarePlus, faLocationDot, faStreetView, faHouse, faGlobe } from '@fortawesome/free-solid-svg-icons'

// keyword use ist just because is is a factory, like useState.
import { useUserActions } from '../common/user-actions';


function NavMenu() {
  NavMenu.displayName = NavMenu.name;

  const userActions = useUserActions();
  const [user, _] = useState(userActions.currentUser());
  const [collapsed, setCollapsed] = useState(true);
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // hides navbar on login page
    setShowNavbar(window.location.pathname !== "/");
  }, []);

  const toggleNavbar = () => {
    // burger menu for small devices
    setCollapsed(!collapsed);
  }

  const closeNavbar = () => {
    setCollapsed(true);
  }

  if (showNavbar) {
    return (
      <header className='app-header'>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">CityExplorer</NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} onClick={closeNavbar} className="text-dark" to="/home" id="link-home">
                  <FontAwesomeIcon icon={faHouse} />
                  <span>Home</span>
                </NavLink>
                <UncontrolledTooltip placement="bottom" placementPrefix="nav-tooltip" target="link-home">
                  Home
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} onClick={closeNavbar} className="text-dark" to="/explore" id="link-explore">
                  <FontAwesomeIcon icon={faStreetView} />
                  <span>Explore</span>
                </NavLink>
                <UncontrolledTooltip placement="bottom" placementPrefix="nav-tooltip" target="link-explore">
                  Explore
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} onClick={closeNavbar} className="text-dark" to="/neighbourhood" id="link-neighbourhood">
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>Neighbourhood</span>
                </NavLink>
                <UncontrolledTooltip placement="bottom" placementPrefix="nav-tooltip" target="link-neighbourhood">
                  Neighbourhood
                </UncontrolledTooltip>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} onClick={closeNavbar} className="text-dark" to="/quick-add" id="link-quick-add">
                  <FontAwesomeIcon icon={faSquarePlus} />
                  <span>
                    Quick Add
                  </span>
                </NavLink>
                <UncontrolledTooltip placement="bottom" placementPrefix="nav-tooltip" target="link-quick-add">
                  Quick Add
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} onClick={closeNavbar} className="text-dark" to="/content-manager" id="link-content">
                  <FontAwesomeIcon icon={faGlobe} />
                  <span>
                    Content
                  </span>
                </NavLink>
                <UncontrolledTooltip placement="bottom" placementPrefix="nav-tooltip" target="link-content">
                  Content
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} onClick={closeNavbar} className="text-dark" to="/settings" id="link-setting">
                  <FontAwesomeIcon icon={faGear} />
                  <span>
                    Settings
                  </span>
                </NavLink>
                <UncontrolledTooltip placement="bottom" placementPrefix="nav-tooltip" target="link-setting">
                  Settings
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                {user &&
                  <NavLink tag={Link} onClick={closeNavbar} className="text-dark" to="/profile">
                    <img src={`/picture/profile-picture/${user.id}/default`} alt="Avatar" style={{ borderRadius: '50%', width: '38px' }} />
                    <span>
                      Profile
                    </span>
                  </NavLink>
                }
              </NavItem>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  } else {
    return null;
  }
}

export default NavMenu;