import  Home  from "./components/Home";
import  Login  from "./components/Login";
import  QuickAdd  from "./components/QuickAdd";
import  Neighbourhood  from "./components/Neighbourhood";
import  Explore  from "./components/Explore";
import  Settings  from "./components/Settings";
import  Profile from "./components/Profile";
import ContentManager from "./components/ContentManager";
import Introduction from "./components/Introduction";
import TermsAndConditions from "./components/TermsAndConditions";
import Imprint from "./components/Imprint"; 

const AppRoutes = [
  {
    index: true,
    element: <Login />
  },
  {
    path: '/Home',
    element: <Home />
  },
  {
    path: '/quick-add',
    element: <QuickAdd />
  },
  {
    path: '/neighbourhood',
    element: <Neighbourhood />
  },
  {
    path: '/explore',
    element: <Explore />
  },
  {
    path: '/settings',
    element: <Settings />
  },
  {
    path: '/content-manager',
    element: <ContentManager />
  },
  {
    path: '/profile',
    element: <Profile />
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditions />
  },
  {
    path: '/introduction',
    element: <Introduction />
  }
  ,
  {
    path: '/imprint',
    element: <Imprint />
  }

];

export default AppRoutes;
