import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, FormText, UncontrolledTooltip } from 'reactstrap'
import GoogleMapReact from 'google-map-react';
import { Badge, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPerson, faInfo, faLocationDot, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { useAuthenticatedFetchApi } from '../common/authenticated-fetch-api';
import { useUserActions } from '../common/user-actions';
import { useVoiceUtility } from '../common/voice-utility';
import { useMapUtility } from '../common/map-utility';
import Multiselect from 'multiselect-react-dropdown';
import { NotificationManager } from 'react-notifications';
import UserMarker from './MapComponents/UserMarker';
import HomePositionMarker from './MapComponents/HomePositionMarker';


function Settings() {
  Settings.displayName = Settings.name;

  const authenticatedFetchApi = useAuthenticatedFetchApi();
  const userActions = useUserActions();
  const voiceUtility = useVoiceUtility();
  const mapUtility = useMapUtility();

  const currentUser = userActions.currentUser();

  const [loading, setLoading] = useState(true);
  const [systemVoices, setSystemVoices] = useState();

  const [language, setLanguage] = useState(currentUser.language);
  const [contentLanguage, setContentLanguage] = useState(currentUser.contentLanguage);
  const [speechSpeed, setSpeechSpeed] = useState(currentUser.speechSpeed)
  const [fontSize, setFontSize] = useState(currentUser.fontSize);
  const [homePosition, setHomePosition] = useState({ latitude: currentUser.homePositionLatitude, longitude: currentUser.homePositionLongitude });
  const [currentPosition, setCurrentPosition] = useState();
  const [voice, setVoice] = useState(currentUser.voice);
  const [theme, setTheme] = useState(currentUser.theme);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(currentUser.userCategories.map(userCategory => userCategory.category));
  const [selectedExplanationModes, setSelectedExplanationModes] = useState(currentUser.userExplanationModes.map(userExplanationMode => userExplanationMode.explanationMode));

  const [explanationModes, setExplanationModes] = useState([]);

  useEffect(() => {
    const initialize = async () => {
      // Center map to current position
      setCurrentPosition(await mapUtility.getCurrentPosition());

    }

    setLoading(false);

    initialize();
  }, []);

  const setMarkerPositionHere = async () => {
    const position = await mapUtility.getCurrentPosition();

    setHomePosition({ latitude: position.latitude, longitude: position.longitude });
  };

  useEffect(() => {
    const initialize = async () => {
      const fetchedCategories = await authenticatedFetchApi.get("category");

      setCategories(fetchedCategories);
    };

    initialize();
  }, []);

  useEffect(() => {
    const initialize = async () => {
      const fetchedExplanationModes = await authenticatedFetchApi.get("explanationmode");

      setExplanationModes(fetchedExplanationModes);

      const voices = await voiceUtility.getVoices();

      setSystemVoices(voices);
    };

    initialize();
  }, []);

  // Save Settings, if somethings changes
  useEffect(() => {
    if (!loading) {
      saveSettings();
    }
  }, [language, contentLanguage, speechSpeed, fontSize, homePosition, voice, theme, selectedExplanationModes, selectedCategories]);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  }

  const handleContentLanguageChange = (event) => {
    setContentLanguage(event.target.value);
  }

  const handleSpeechSpeedChange = (event) => {
    setSpeechSpeed(parseFloat(event.target.value));
  }

  const handleFontSizeChange = (event) => {
    setFontSize(parseInt(event.target.value));
  }

  const handleVoiceChange = (event) => {
    setVoice(event.target.value);
  }

  const handleThemeChange = (event) => {
    setTheme(parseInt(event.target.value));
  }

  const onSelectExplanationMode = (selectedList, _) => {
    setSelectedExplanationModes(selectedList)
  };

  const onRemoveExplanationMode = (selectedList, _) => {
    setSelectedExplanationModes(selectedList);
  };

  const onSelectCategory = (selectedList, _) => {
    setSelectedCategories(selectedList)
  };

  const onRemoveCategory = (selectedList, _) => {
    setSelectedCategories(selectedList);
  };

  const saveSettings = () => {
    const userSettings = {
      "language": language,
      "contentLanguage": contentLanguage,
      "speechSpeed": speechSpeed,
      "fontSize": fontSize,
      "voice": voice,
      "theme": theme,
      "explanationModes": selectedExplanationModes,
      "categories": selectedCategories,
      "homePositionLatitude": homePosition.latitude,
      "homePositionLongitude": homePosition.longitude
    };

    authenticatedFetchApi.post("/auth/update-settings", userSettings).then(updatedUser => {
      userActions.updateUser(updatedUser);

      NotificationManager.success('Saved');
    }, () => {
      NotificationManager.error('Could not save settings.');
    });
  }

  const setMarkerPosition = (event) => {
    // Acutal clicked position
    setHomePosition({ latitude: event.lat, longitude: event.lng });
  }

  const renderMap = (currentPosition, marker) => {
    return (<div style={{ height: '250px', width: '100%' }}>
      {currentPosition &&
        <GoogleMapReact
          onClick={setMarkerPosition}
          bootstrapURLKeys={{ key: "AIzaSyDjXPpOXmShEoP2OblhU4maG84QhhE1L-M" }}
          center={{
            lat: currentPosition?.latitude,
            lng: currentPosition?.longitude
          }}
          options={mapUtility.getMapOptions}
          defaultZoom={11}
        >
          {marker && <UserMarker
            key="homePositionMarker"
            lat={marker.latitude}
            lng={marker.longitude} />}
        </GoogleMapReact>
      }
    </div>);
  }


  const render = () => {
    const map = renderMap(currentPosition, homePosition);

    return (
      <div>
        <h1>Settings</h1>
        <Form id="settingsForm">
          <FormGroup>

            {map}
            <Label className='margin-top-class' for="settingHomePosition">
              <FormText>
                Place the marker by clicking on the desired location or click the button below to set it to the current position *
              </FormText>
              <br />
              <Button onClick={setMarkerPositionHere}>
                <FontAwesomeIcon icon={faLocationDot} /> Set to here!
              </Button>
              <br /><br />
              <HomePositionMarker></HomePositionMarker> {homePosition.latitude} {homePosition.longitude}
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="settingLanguage">
              Application Language  <span id="infoApplicationLanguage"><FontAwesomeIcon icon={faCircleInfo} className="infoIcon" /></span>
              <UncontrolledTooltip
                placement="top"
                target="infoApplicationLanguage"
                trigger="click">This is the prefereed language of the application on the mobile device or in the browser. If something is not localized, then the fallback will be english.
              </UncontrolledTooltip>
            </Label>

            <Input id="settingLanguage" name="select" type="select" value={language} onChange={handleLanguageChange}>
              <option>de</option>
              <option>en</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="settingContentLanguage">
              Content Language  <span id="infoContentLanguage"><FontAwesomeIcon icon={faCircleInfo} className="infoIcon" /></span>
              <UncontrolledTooltip
                placement="top"
                target="infoContentLanguage"
                trigger="click">This is the preffered language of the content. If some content is not localized, then the fallback will be german.
              </UncontrolledTooltip>
            </Label>

            <Input id="settingContentLanguage" name="select" type="select" value={contentLanguage} onChange={handleContentLanguageChange}>
              <option>de</option>
              <option>en</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="settingImpact">
              Impact <span id="infoImpact"><FontAwesomeIcon icon={faCircleInfo} className="infoIcon" /></span>
              <UncontrolledTooltip
                placement="top"
                target="infoImpact"
                trigger="click">
                This are the desired categories of the POIs.
              </UncontrolledTooltip>
            </Label>
            <Multiselect
              options={categories}
              selectedValues={selectedCategories}
              onSelect={onSelectCategory}
              onRemove={onRemoveCategory}
              displayValue="name"
            />
          </FormGroup>

          <FormGroup>
            <Label for="settingSpeechSpeed">
              Speech Speed  <span id="infoSpeechSpeed"><FontAwesomeIcon icon={faCircleInfo} className="infoIcon" /></span>
              <UncontrolledTooltip
                placement="top"
                target="infoSpeechSpeed"
                trigger="click">This is the speed of the speech speed of the synthesizer.  <br />For example: <br />* normal speed is 1.0 <br /> * double speed is 2.0 <br />
              </UncontrolledTooltip>
            </Label>

            <Input id="settingSpeechSpeed" name="select" type="select" value={speechSpeed} onChange={handleSpeechSpeedChange}>
              <option value="0.1">.1</option>
              <option value="0.25">.25</option>
              <option value="0.5">.5</option>
              <option value="0.75">.75</option>
              <option value="1">1.0</option>
              <option value="1.25">1.25</option>
              <option value="1.5">1.5</option>
              <option value="1.75">1.75</option>
              <option value="2">2.0</option>
              <option value="3">3.0</option>
              <option value="4">4.0</option>
              <option value="5">5.0</option>
              <option value="7">7.0</option>
              <option value="10">10.0</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="settingFontSize">
              Font Size  <span id="infoFontSize"><FontAwesomeIcon icon={faCircleInfo} className="infoIcon" /></span>
              <UncontrolledTooltip
                placement="top"
                target="infoFontSize"
                trigger="click">The font size dictates the height of the lettering of the content and application. <br />
              </UncontrolledTooltip>
            </Label>

            <Input id="settingFontSize" name="select" type="select" value={fontSize} onChange={handleFontSizeChange}>
              <option value="0">Small</option>
              <option value="1">Normal</option>
              <option value="2">Large</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="settingVoice">
              Voice <span id="infoVoice"><FontAwesomeIcon icon={faCircleInfo} className="infoIcon" /></span>
              <UncontrolledTooltip
                placement="top"
                target="infoVoice"
                trigger="click">
                The voice is used to read the content of the application. The voies are the default system voices from your browser or moile device.
              </UncontrolledTooltip>
            </Label>

            <Input id="settingVoice" name="select" type="select" value={voice} onChange={handleVoiceChange}>
              {systemVoices && systemVoices.map(systemVoice =>
                <option key={systemVoice.voiceId} value={systemVoice.voiceId}>{systemVoice.voiceName}</option>
              )}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="settingTheme">
              Theme <span id="infoTheme"> <FontAwesomeIcon icon={faCircleInfo} className="infoIcon" /></span>
              <UncontrolledTooltip
                placement="top"
                target="infoTheme"
                trigger="click">
                The theme supports a dark and an light mode. <br />Default is the light mode.
              </UncontrolledTooltip>
            </Label>

            <Input id="settingTheme" name="select" type="select" value={theme} onChange={handleThemeChange}>
              <option value="0">Light</option>
              <option value="1">Default</option>
              <option value="2">Dark</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="settingExplanationMode">
              Explanation Mode  <span id="infoExplanationMode"> <FontAwesomeIcon icon={faCircleInfo} className="infoIcon" /></span>
              <UncontrolledTooltip
                placement="top"
                target="infoExplanationMode"
                trigger="click">
                The explanation mode is used when the content is read by the speech synthesizer. If you reach an point of interest, then your selection will be considerd. Do you want to hear just the title, or the title and the short description or everything. This means, title, short- and long description of an POI.
              </UncontrolledTooltip>
            </Label>
            <Multiselect
              options={explanationModes}
              selectedValues={selectedExplanationModes}
              onSelect={onSelectExplanationMode}
              onRemove={onRemoveExplanationMode}
              displayValue="name"
            />
          </FormGroup>
          <div><br /><br /><FontAwesomeIcon icon={faCircleInfo} className="" /> &nbsp;Settings will be applied if you restart the application</div>
        </Form>


      </div>
    );
  }
  return render();
}
export default Settings;