import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Button, UncontrolledTooltip, Accordion, AccordionBody, AccordionHeader, AccordionItem, Form, FormGroup, Label, Col, Row, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faForward, faPerson, faRepeat, faComment, faCommentDots, faCircleDot, faLocationPin, faLocationPinLock, faHeart, faLock, faXmark, faHeartCrack, faCircleInfo, faStop, faFlagCheckered, faRoad, faEarListen, faEarDeaf, faFilter, faLocationDot, faCrosshairs } from '@fortawesome/free-solid-svg-icons'
import Overlay from "react-overlay-component";
import PatternLock from "react-pattern-lock";
import { useAuthenticatedFetchApi } from '../common/authenticated-fetch-api';
import { useUserActions } from '../common/user-actions';
import { useVoiceUtility } from '../common/voice-utility';
import { useMapUtility } from '../common/map-utility';
import { useVoiceCommandUtility } from '../common/voice-command-utility';
import { useDebouncedCallback } from 'use-debounce';
import './Explore.css';
import startIndicator from '../assets/start_indicator.mp3';
import stopIndicator from '../assets/stop_indicator.wav';
import alreadyVisitedIndicator from '../assets/already_visited_indicator.mp3';
import infoUnlockPattern from '../assets/info-unlock-pattern.png';
import { NotificationManager } from 'react-notifications';
import { Gallery } from "react-grid-gallery";
import UserMarker from './MapComponents/UserMarker';
import VoiceCommandHelp from './SharedHTML/VoiceCommandHelp';
import PoiMarker from './MapComponents/PoiMarker';
import PointOfInterestModal from "./SharedHTML/PointOfInterestModal";
import { Spinner } from 'reactstrap';

const overlayConfiguration = {
    animate: true,
    clickDismiss: false,
    escapeDismiss: false,
    contentClass: "lock-screen-overlay"
};


function Explore() {
    Explore.displayName = Explore.name;

    const authenticatedFetchApi = useAuthenticatedFetchApi();
    const userActions = useUserActions();
    const voiceUtility = useVoiceUtility();
    const mapUtility = useMapUtility();
    const voiceCommandUtility = useVoiceCommandUtility();

    const startIndicatorSound = new Audio(startIndicator);
    const stopIndicatorSound = new Audio(stopIndicator);
    const alreadyVisitedIndicatorSound = new Audio(alreadyVisitedIndicator);

    const [pointsOfInterest, setPointsOfInterest] = useState([]);
    const [currentPointOfInterest, setCurrentPointOfInterest] = useState();
    const [currentPosition, setCurrentPosition] = useState();
    const [previousPosition, setPreviousPosition] = useState();
    const [currentUser, setCurrentUser] = useState(userActions.currentUser());
    const [initialPosition, setInitialPosition] = useState();
    const [loading, setLoading] = useState(true);
    const [overlay, setOverlay] = useState({ isOpen: false });
    const [lockPattern, setLockPattern] = useState([]);
    const [wakeLockSupported, setWakeLockSupported] = useState(false);
    const [wakeLock, setWakeLock] = useState();

    const [searchRange, setSearchRange] = useState(20);
    const [visibleSearchRange, setVisibleSearchRange] = useState(20);

    const [isExploring, setExploring] = useState(false);
    const [currentRouteId, setCurrentRouteId] = useState();

    const [speaking, setSpeaking] = useState(false);

    // Explore hints should be collapsed by default (0)
    const [openExploreHints, setOpenExploreHints] = useState('0');

    const [mapInstance, setMapInstance] = useState();
    const [mapsInstance, setMapsInstance] = useState();
    const [nightMode, _] = useState(mapUtility.isNight());
    const [voice, setVoice] = useState();
    const [locationWatchId, setLocationWatchId] = useState(0);
    const [currentVoiceCommand, setCurrentVoiceCommand] = useState();
    const [listening, setListening] = useState(false);
    const [lastPatternLockFinished, setLastPatternLockFinished] = useState(Infinity);

    // State for modal
    const [modalState, setModalState] = useState({ isOpen: false, selectedPictureIndex: 0 });

    const SPEECH_SPEED_STEP = 0.5;

    useEffect(() => {
        populatePointsOfInterestData();
    }, []);

    useEffect(() => {
        const initCurrentPosition = async () => {
            mapUtility.getCurrentPosition().then((position) => {
                setInitialPosition(position);
            }, (error) => {
                NotificationManager.error(error);
            });
        }

        initCurrentPosition();
    }, []);

    useEffect(() => {
        voiceCommandUtility.addCommandHandlers([
            {
                command: voiceCommandUtility.COMMAND_START_EXPLORING,
                execute: () => { startExploring(); }
            }, {
                command: voiceCommandUtility.COMMAND_STOP_EXPLORING,
                execute: () => { stopExploring(); }
            }, {
                command: voiceCommandUtility.COMMAND_START_EXPLORATION,
                execute: () => { startExploring(); }
            }, {
                command: voiceCommandUtility.COMMAND_STOP_EXPLORATION,
                execute: () => { stopExploring(); }
            }, {
                command: voiceCommandUtility.COMMAND_SKIP,
                execute: () => { setCurrentVoiceCommand(`skip-${new Date().getTime()}`); }
            }, {
                command: voiceCommandUtility.COMMAND_RESET_POI,

                execute: () => { setCurrentVoiceCommand(`unskip-${new Date().getTime()}`); }
            }, {
                command: voiceCommandUtility.COMMAND_UNSKIP,

                execute: () => { setCurrentVoiceCommand(`unskip-${new Date().getTime()}`); }
            }, {
                command: voiceCommandUtility.COMMAND_AGAIN,
                execute: () => { setCurrentVoiceCommand(`unvisit-${new Date().getTime()}`); }
            }, {
                command: voiceCommandUtility.COMMAND_START,
                execute: () => { setCurrentVoiceCommand(`unvisit-${new Date().getTime()}`); }
            }, {
                command: voiceCommandUtility.COMMAND_STOP,
                execute: () => { stopSpeaking(); }
            }
            /*, 
            ,{
                command: voiceCommandUtility.COMMAND_SET_RANGE,
                execute: () => { setRange(); }
            },*/
            , {
                command: voiceCommandUtility.COMMAND_INCREASE_SPEECH_SPEED,
                execute: () => { increaseSpeechSpeed(); }
            }, {
                command: voiceCommandUtility.COMMAND_DECREASE_SPEECH_SPEED,
                execute: () => { decreaseSpeechSpeed(); }
            }, {
                command: voiceCommandUtility.COMMAND_FASTER,
                execute: () => { increaseSpeechSpeed(); }
            }, {
                command: voiceCommandUtility.COMMAND_SLOWER,
                execute: () => { decreaseSpeechSpeed(); }
            }, {
                command: voiceCommandUtility.COMMAND_TITLE,
                execute: () => { setCurrentVoiceCommand(`tellTitle-${new Date().getTime()}`); }
            }, {
                command: voiceCommandUtility.COMMAND_SHORT,
                execute: () => { setCurrentVoiceCommand(`tellShortDescription-${new Date().getTime()}`); }
            }, {
                command: voiceCommandUtility.COMMAND_LONG,
                execute: () => { setCurrentVoiceCommand(`tellLongDescription-${new Date().getTime()}`); }
            }, {
                command: voiceCommandUtility.COMMAND_SHORT_DESCRIPTION,
                execute: () => { setCurrentVoiceCommand(`tellShortDescription-${new Date().getTime()}`); }
            }, {
                command: voiceCommandUtility.COMMAND_LONG_DESCRIPTION,
                execute: () => { setCurrentVoiceCommand(`tellLongDescription-${new Date().getTime()}`); }
            }, {
                command: voiceCommandUtility.COMMAND_IMAGES,
                execute: () => { setCurrentVoiceCommand(`tellImagesDescription-${new Date().getTime()}`); }
            }]);
    }, []);

    useEffect(() => {
        const loadVoice = async () => {
            if (currentUser) {
                const loadedVoice = await voiceUtility.getVoiceById(currentUser.voice);

                setVoice(loadedVoice);
            }
        }

        loadVoice();
    }, [currentUser]);

    useEffect(() => {
        // When we leave the exploring-page then stop watch and cancle speechsynthesis
        return () => {
            disableWakeLock();
            stopLocationWatch();

            console.log("Cleaned up location watch");

            speechSynthesis.cancel();
        };
    }, []);

    useEffect(() => {
        if (previousPosition) {
            const distanceToPreviousPosition = calculateLength(previousPosition.latitude, previousPosition.longitude, currentPosition.latitude, currentPosition.longitude);

            // We ignore little position changes to improve performance
            if (distanceToPreviousPosition >= 1) {
                setPreviousPosition(currentPosition);

                handlePositionChanged();
            }
        } else {
            setPreviousPosition(currentPosition);

            handlePositionChanged();
        }

    }, [currentPosition]);

    useEffect(() => {
        // Start speaking
        if (currentPointOfInterest) {
            if (!currentPointOfInterest.visited) {
                visit(currentPointOfInterest);

                startSpeaking(currentPointOfInterest);
            } else if ((currentPointOfInterest.visitedDate && (new Date().getTime() - currentPointOfInterest.visitedDate) > 1000) || !currentPointOfInterest.visitedDate) {
                startAlreadyVisited(currentPointOfInterest);

                startListenToSingleCommand();
            }

            console.log(`Already visited "${currentPointOfInterest.title}"`);
        }
    }, [currentPointOfInterest]);

    useEffect(() => {
        if (currentVoiceCommand) {
            const currentVoiceCommandWithoutTime = currentVoiceCommand.substring(0, currentVoiceCommand.indexOf("-"));

            switch (currentVoiceCommandWithoutTime) {
                case "tellTitle":
                    tellTitle();
                    break;
                case "tellShortDescription":
                    tellShortDescription();
                    break;
                case "tellLongDescription":
                    tellLongDescription();
                    break;
                case "tellImagesDescription":
                    tellImagesDescription();
                    break;
                case "skip":
                    skip();
                    break;
                case "unskip":
                    unskip();
                    break;
                case "like":
                    like();
                    break;
                case "unlike":
                    unlike();
                    break;
                case "unvisit":
                    unvisit();
                    break;
            }
        }
    }, [currentVoiceCommand]);

    const handlePositionChanged = () => {
        if (currentPosition) {
            if (!currentRouteId) {
                authenticatedFetchApi.post("routepoint/start", { latitude: currentPosition.latitude, longitude: currentPosition.longitude }).then(response => {
                    setCurrentRouteId(response.routeId);
                });
            } else {
                authenticatedFetchApi.post("routepoint/add", { latitude: currentPosition.latitude, longitude: currentPosition.longitude, routeId: currentRouteId });
            }

            // Update distance between POIs and current position
            for (const pointOfInterest of pointsOfInterest) {
                const distance = calculateLength(pointOfInterest.latitude, pointOfInterest.longitude, currentPosition.latitude, currentPosition.longitude);

                pointOfInterest.currentDistance = distance;
            }

            // Get all points within searchRange (e.g. 20m)
            const pointsOfInterestWithDistance = pointsOfInterest.filter(pointOfInterest => pointOfInterest.currentDistance < searchRange);

            if (pointsOfInterestWithDistance.length > 0) {
                // Sort the points by their distance asc
                const sortedPointsOfInterestByDistance = pointsOfInterestWithDistance.sort(function (a, b) {
                    return a.currentDistance - b.currentDistance;
                });

                // Always take the nearest
                approachedPointOfInterest(sortedPointsOfInterestByDistance[0]);
            }
        }
    };

    const like = async () => {
        if (currentPointOfInterest) {
            await authenticatedFetchApi.post('pointofinterest/like', { pointOfInterestId: currentPointOfInterest.id });

            const updatedCurrentPointOfInterest = { ...currentPointOfInterest, liked: true };

            updatedCurrentPointOfInterest.likes = [...updatedCurrentPointOfInterest.likes, {
                userId: currentUser.id,
                user: currentUser,
                pointOfInterestId: currentPointOfInterest.id,
                pointsOfInterest: currentPointOfInterest
            }];

            setCurrentPointOfInterest(updatedCurrentPointOfInterest);
            updatePointsOfInterest(updatedCurrentPointOfInterest);
        }
    };

    const unlike = async () => {
        if (currentPointOfInterest) {
            await authenticatedFetchApi.post('pointofinterest/unlike', { pointOfInterestId: currentPointOfInterest.id });

            const updatedCurrentPointOfInterest = { ...currentPointOfInterest, liked: false };

            updatedCurrentPointOfInterest.likes = updatedCurrentPointOfInterest.likes.filter(like => like.userId !== currentUser.id);

            setCurrentPointOfInterest(updatedCurrentPointOfInterest);
            updatePointsOfInterest(updatedCurrentPointOfInterest);
        }
    };

    const skip = async () => {
        if (currentPointOfInterest) {
            await authenticatedFetchApi.post('pointofinterest/skip', { pointOfInterestId: currentPointOfInterest.id });

            const updatedCurrentPointOfInterest = { ...currentPointOfInterest, skipped: true };

            setCurrentPointOfInterest(updatedCurrentPointOfInterest);
            updatePointsOfInterest(updatedCurrentPointOfInterest);

            stopSpeaking();
        }
    };

    const unskip = async () => {
        if (currentPointOfInterest) {
            await authenticatedFetchApi.post('pointofinterest/unskip', { pointOfInterestId: currentPointOfInterest.id });

            const updatedCurrentPointOfInterest = { ...currentPointOfInterest, skipped: false };

            setCurrentPointOfInterest(updatedCurrentPointOfInterest);
            updatePointsOfInterest(updatedCurrentPointOfInterest);
        }
    };

    const visit = async (pointOfInterest) => {
        if (pointOfInterest) {
            await authenticatedFetchApi.post('pointofinterest/visit', { pointOfInterestId: pointOfInterest.id });

            const updatedCurrentPointOfInterest = { ...pointOfInterest, visited: true, visitedDate: new Date().getTime() };

            setCurrentPointOfInterest(updatedCurrentPointOfInterest);
            updatePointsOfInterest(updatedCurrentPointOfInterest);
        }
    };

    const unvisit = async () => {
        if (currentPointOfInterest) {
            await authenticatedFetchApi.post('pointofinterest/unvisit', { pointOfInterestId: currentPointOfInterest.id });

            const updatedCurrentPointOfInterest = { ...currentPointOfInterest, visited: false };

            setCurrentPointOfInterest(updatedCurrentPointOfInterest);
            updatePointsOfInterest(updatedCurrentPointOfInterest);
        }
    };

    const updatePointsOfInterest = (updatedPointOfInterest) => {
        const updatedPointsOfInterest = pointsOfInterest.map(pointOfInterest => {
            if (pointOfInterest.id === updatedPointOfInterest.id) {
                return updatedPointOfInterest;
            } else {
                return pointOfInterest;
            }
        });

        setPointsOfInterest(updatedPointsOfInterest);
    };

    const handleSearchRangeChange = (event) => {
        const radius = parseInt(event.target.value);

        setVisibleSearchRange(radius);

        handleDebouncedSearchRangeChange(radius)
    }

    const handleDebouncedSearchRangeChange = useDebouncedCallback(
        // Function
        (radius) => {
            setSearchRange(radius);
        },
        // Delay in ms
        500
    );

    const enableWakeLock = async () => {
        if ('wakeLock' in navigator) {
            setWakeLockSupported(true);

            try {
                setWakeLock(await navigator.wakeLock.request('screen'));

                NotificationManager.success("Wakelock is active. Your screen will now stay on.");
            } catch (err) {
                NotificationManager.error("Could not activate wakelock. Exploration will stop if the display turns off.");
            }
        } else {
            setWakeLockSupported(false);
        }
    }

    const disableWakeLock = () => {
        if (wakeLockSupported && wakeLock) {
            wakeLock.release().then(() => {
                setWakeLock(null);

                NotificationManager.success("Wakelock released.");
            });
        }
    }

    const openOverlay = () => {
        setOverlay({ isOpen: true });
    };

    const closeOverlay = () => {
        setOverlay({ isOpen: false });
    };

    const toggleExploreHints = (id) => {
        if (openExploreHints === id) {
            setOpenExploreHints();
        } else {
            setOpenExploreHints(id);
        }
    };

    const setPattern = (pattern) => {
        setLockPattern(pattern);
    };

    const handleFinish = () => {
        setLastPatternLockFinished(new Date().getTime());

        // Differs from mobile device number -1
        if (lockPattern.join("-") === "6-4-2") {
            closeOverlay();
            setLockPattern([]);
        } else {
            setLockPattern([]);
        }
    };

    const populatePointsOfInterestData = async () => {
        const pois = await authenticatedFetchApi.get('pointofinterest/point-of-interests-for-exploration');

        for (const poi of pois) {
            poi.galleryImages = [];

            for (const picture of poi.pictures) {
                const galleryImage = {
                    src: `/picture/${picture.id}/300`,
                    width: picture.width,
                    height: picture.height,
                    caption: poi.title,
                    author: poi.author?.name || "No Author"
                };

                poi.galleryImages.push(galleryImage);
            }
        }

        setPointsOfInterest(pois);
        setLoading(false);
    };

    const stopExploring = () => {
        setExploring(false);
        setCurrentPointOfInterest(null);
        disableWakeLock();
        stopSpeaking();

        if (voiceCommandUtility) {
            voiceCommandUtility.stop();
        }

        stopLocationWatch();
    };


    const startExploring = () => {
        setExploring(true);
        enableWakeLock();

        if (voiceCommandUtility) {

            const continousListeningEnabled = voiceCommandUtility.start(true, {
                callback: (isListening) => {
                    setListening(isListening);
                }
            });

            if (!continousListeningEnabled) {
                NotificationManager.warning("Continous listening is not supported on this device.");
            }
        }

        startLocationWatch();
    };

    const startListenToSingleCommand = () => {
        if (!listening) {
            const started = voiceCommandUtility.start(false, {
                callback: (isListening) => {
                    setListening(isListening);
                }
            });

            if (!started) {
                NotificationManager.warning("Could not listen to commands.");
            }
        }
    };

    const startAlreadyVisited = async (pointOfInterest) => {
        if (pointOfInterest) {

            await alreadyVisitedIndicatorSound.play();

            // Wait one second.
            setTimeout(() => {

            }, 1000);
        }
    }

    const startSpeaking = async (pointOfInterest, overrideExplanationMode) => {
        if (pointOfInterest) {

            speechSynthesis.cancel();

            await startIndicatorSound.play();

            // Wait one second to start.
            setTimeout(() => {

                let addTitle = false;
                let addShortDescription = false;
                let addLongDescription = false;
                let addImageDescription = false;

                if (overrideExplanationMode) {
                    switch (overrideExplanationMode) {
                        case "Title": addTitle = true; break;
                        case "Short Description": addShortDescription = true; break;
                        case "Long Description": addLongDescription = true; break;
                        case "Images": addImageDescription = true; break;
                    }
                } else if (currentUser.userExplanationModes && currentUser.userExplanationModes.length > 0) {
                    for (const userExplanationMode of currentUser.userExplanationModes) {
                        switch (userExplanationMode.explanationMode.name) {
                            case "Title": addTitle = true; break;
                            case "Short Description": addShortDescription = true; break;
                            case "Long Description": addLongDescription = true; break;
                            case "Images": addImageDescription = true; break;
                        }
                    }
                } else {
                    // If nothing configured, then all: title + shortdescription + longdescription
                    addTitle = true;
                    addShortDescription = true;
                    addLongDescription = true;
                    addImageDescription = true;
                }

                let text = "";

                if (addTitle) {
                    text += pointOfInterest.title + "\n";
                }


                if (addShortDescription) {
                    text += pointOfInterest.shortDescription + "\n";
                }
                else if (!addShortDescription && pointOfInterest.shortDescription?.length > 0) {
                    text += "Es gibt eine Kurzbeschreibung. " + "\n";
                }

                if (addLongDescription) {
                    text += pointOfInterest.longDescription + "\n";
                }
                else if (!addLongDescription && pointOfInterest.longDescription?.length > 0) {
                    text += "Es gibt eine Langbeschreibung." + "\n";
                }

                // Add image information to the data.
                if (pointOfInterest.pictures?.length > 0) {
                    let imageDescriptions = pointOfInterest.pictures.map(image => image.title + " " + image.description + " " + image.reference).join(" ");

                    // Text needs to be localized.
                    //text += "\nThe point of interest has " + pointOfInterest.pictures.length + " images. ";
                    text += "\n Diese Sehenswürdigkeit hat " + pointOfInterest.pictures.length + " Bilder verknüpft. ";

                    if (imageDescriptions.trim().length > 0) {

                        if (addImageDescription) {
                            text += imageDescriptions;
                        }
                    }
                    //else if (addImageDescription) {
                    //    text += "\n Diese Sehenswürdigkeit hat keine Bildbeschreibungen.";
                    //}
                }


                // Set the text for the speech synthesis
                // Aeuszerung, expression, term... something like this
                let utterance = new SpeechSynthesisUtterance(text);


                if (voice) {
                    utterance.voice = voice;
                }

                if (0.1 <= currentUser.speechSpeed && currentUser.speechSpeed <= 10) {
                    utterance.rate = currentUser.speechSpeed;
                } else {
                    utterance.rate = 1;
                }

                utterance.lang = "de-AT";

                // Define event for onend before starting speak
                utterance.onend = async () => {
                    // State for gui button
                    setSpeaking(false);

                    await stopIndicatorSound.play();

                    setTimeout(() => {
                        startListenToSingleCommand();
                    }, 1000);
                };

                // speechSynthesis exits from the browser.
                speechSynthesis.speak(utterance);
                setSpeaking(true);
            }, 1000);
        }
    }

    const increaseSpeechSpeed = () => {
        currentUser.speechSpeed += SPEECH_SPEED_STEP;
        NotificationManager.success("Speech speed set to " + currentUser.speechSpeed + "for the next time.");
    }

    const decreaseSpeechSpeed = () => {
        currentUser.speechSpeed -= SPEECH_SPEED_STEP;
        NotificationManager.success("Speech speed set to " + currentUser.speechSpeed + "for the next time.");
    }

    const tellTitle = () => {
        startSpeaking(currentPointOfInterest, "Title");
    };

    const tellShortDescription = () => {
        startSpeaking(currentPointOfInterest, "Short Description");
    };

    const tellLongDescription = () => {
        startSpeaking(currentPointOfInterest, "Long Description");
    };
    const tellImagesDescription = () => {
        startSpeaking(currentPointOfInterest, "Images");
        setModalState({ isOpen: true, selectedPictureIndex: 0 });
    };


    const stopSpeaking = () => {
        speechSynthesis.cancel();

        // State for Button
        setSpeaking(false);
    };

    const startLocationWatch = () => {
        if ("geolocation" in navigator) {
            // naviator.geolocation. standard from mobile browser Geolocation(clearWatch, getCurrentPosition, watchPosition)
            // Watcher if geo position from the mobile device changes calls the callback with the current position
            setLocationWatchId(navigator.geolocation.watchPosition(
                // function watchPosition(callback)
                position => {
                    setCurrentPosition(position.coords);
                },
                error => {
                    alert(error);
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 1000,
                    timeout: 10000
                }
            ));
        } else {
            alert("No geolocation available");
        }
    };

    const approachedPointOfInterest = (pointOfInterest) => {
        // Nearest point, but not my current which I am hearing
        if (currentPointOfInterest?.id !== pointOfInterest?.id && !speaking && currentUser) {
            setCurrentPointOfInterest(pointOfInterest);
        }
    };

    const stopLocationWatch = () => {
        // Looks if device supports navigator.geolocation 
        if ("geolocation" in navigator) {
            if (locationWatchId) {
                navigator.geolocation.clearWatch(locationWatchId);

                console.log(locationWatchId + " cleared");

                setLocationWatchId(0);
            }
        }
    };

    const handleOverlayTap = () => {
        if ((lastPatternLockFinished === Infinity || (new Date().getTime() - lastPatternLockFinished) > 500)) {
            startListenToSingleCommand();
        }
    };

    const openModal = (index) => {
        setModalState({ isOpen: true, selectedPictureIndex: index });
    }

    const closeModal = () => {
        setModalState({ isOpen: false, selectedPictureIndex: 0 });
    }

    const calculateLength = (lat1, lon1, lat2, lon2) => {
        const R = 6371e3; // metres
        const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d = R * c; // in metres

        return d;
    };

    const handleApiLoaded = (map, maps) => {
        setMapInstance(map);
        setMapsInstance(maps);
    };

    const centerMap = () => {
        if (mapInstance && currentPosition) {
            mapInstance.setCenter(new mapsInstance.LatLng(currentPosition.latitude, currentPosition.longitude));
        }
    };


    // // TODO: Build a icon component to share this logic
    // const getPointOfInterestClass = (pointOfInterest) => {
    //     if (pointOfInterest.visited) {
    //         return "poi visited";
    //     }
    //     else if (pointOfInterest.skipped) {
    //         return "poi skipped";
    //     } else if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 0) {
    //         // Own public poi
    //         return "poi own public"; 
    //     } else if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 1) {
    //         // Own private poi
    //         return "poi own private";
    //     } else {
    //         return "poi other public";
    //     }
    // }; 

    // // TODO: Build a icon component to share this logic
    // const renderPointOfInterestIcon = (pointOfInterest) => {
    //     if (pointOfInterest.visited) {
    //         return <FontAwesomeIcon icon={faLocationDot} className="poi visited" />;
    //     }
    //     else if (pointOfInterest.skipped) {
    //         return <FontAwesomeIcon icon={faLocationDot} className="poi skipped" />;
    //     } else if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 0) {
    //         // Own public poi
    //         return <FontAwesomeIcon icon={faLocationDot} className="poi own public" />
    //     } else if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 1) {
    //         // Own private poi
    //         return <FontAwesomeIcon icon={faLocationPinLock} className="poi own private" />
    //     } else {
    //         return <FontAwesomeIcon icon={faLocationDot} className="poi" />;
    //     }
    // };

    return (
        <div>
            <h1>Explore</h1>

            {(loading || !initialPosition)
                ? <div className='exploration-loading'>
                    <Spinner animation="grow" variant="dark" /><br />Loading data ...
                </div>
                : <>
                    <div>
                        <div>
                            <div style={{ height: '50vh', width: '100%' }}>
                                {initialPosition &&
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyDjXPpOXmShEoP2OblhU4maG84QhhE1L-M" }}
                                        defaultCenter={{
                                            lat: initialPosition?.latitude,
                                            lng: initialPosition?.longitude
                                        }}
                                        options={mapUtility.getMapOptions}
                                        defaultZoom={17}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                    >
                                        {pointsOfInterest.map(pointOfInterest =>
                                            <PoiMarker
                                                key={pointOfInterest.id}
                                                lat={pointOfInterest.latitude}
                                                lng={pointOfInterest.longitude}
                                                text={pointOfInterest.title}
                                                nightMode={nightMode}
                                                poiId={pointOfInterest.id}
                                                own={pointOfInterest.author?.id === currentUser?.id}
                                                visited={pointOfInterest.visited}
                                                skipped={pointOfInterest.skipped}
                                                privacy={pointOfInterest.privacy}
                                            />
                                        )}

                                        {currentPosition && <UserMarker
                                            key="homePositionMarker"
                                            lat={currentPosition.latitude}
                                            lng={currentPosition.longitude} />}
                                    </GoogleMapReact>

                                }

                            </div>
                            <div className='exploring-container-legend'>
                                <div className={'exploring-legend'}>
                                    <FontAwesomeIcon icon={faCircleInfo} className='circle-info-icon'></FontAwesomeIcon>
                                    <a id="info-hide-other-public" className={(nightMode ? "night-mode poi other public" : "day-mode poi other public")} > <FontAwesomeIcon icon={faLocationPin} /></a>
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="info-hide-other-public"
                                        trigger="click">Other public POIs
                                    </UncontrolledTooltip>
                                    <a id="info-hide-own-public" className="poi own public"> <FontAwesomeIcon icon={faLocationDot} /></a>

                                    <UncontrolledTooltip
                                        placement="top"
                                        target="info-hide-own-public"
                                        trigger="click">Own private POIs
                                    </UncontrolledTooltip>
                                    <a id="info-hide-own-private" className="poi own private"> <FontAwesomeIcon icon={faLocationPinLock} /></a>
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="info-hide-own-private"
                                        trigger="click">Own private POIs
                                    </UncontrolledTooltip>
                                    <a id="info-hide-visited" className="poi visited"> <FontAwesomeIcon icon={faLocationDot} /></a>
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="info-hide-visited"
                                        trigger="click">Visited/skipped POIs
                                    </UncontrolledTooltip>

                                    {currentPosition &&
                                        <a onClick={centerMap}>
                                            <FontAwesomeIcon icon={faCrosshairs} />
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='clearer' />

                        <Form>
                            <FormGroup row>
                                <Col xs={3}>
                                    <Label for="exampleEmail">
                                        <FontAwesomeIcon className="userCurrentPositionIcon" icon={faPerson} />
                                        Current Location
                                    </Label>
                                </Col>
                                <Col xs={9}>
                                    <span style={{ lineHeight: 38 + 'px' }}>
                                        {currentPosition
                                            ? <>{currentPosition.latitude}, {currentPosition.longitude}</>
                                            : <>Loading position ...</>
                                        }
                                    </span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Row>
                                    <Col xs={3}>
                                        <Label for="searchRange">
                                            <FontAwesomeIcon className="userCurrentPositionIcon" icon={faCircleDot} />
                                            Range
                                        </Label>
                                    </Col>

                                    <Col xs={9}>
                                        <Input
                                            id="searchRange"
                                            name="range"
                                            type="range"
                                            min="5"
                                            max="100"
                                            value={visibleSearchRange}
                                            onChange={handleSearchRangeChange}
                                        />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={3}></Col>
                                    <Col xs={1}>{5}m</Col>
                                    <Col xs={7} className="text-center">{visibleSearchRange}m</Col>
                                    <Col xs={1} className="searchRangeMax">
                                        <span>{100}m</span>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>

                        {currentPointOfInterest &&
                            <div className="explore-poi-data">
                                <div className='explore-poi-detail'>
                                    {!currentPointOfInterest.visited
                                        ? <p>Currently playing with speed {currentUser.speechSpeed} and voice {voice.name}.</p>
                                        : <div>
                                            <p>Currently playing with speed {currentUser.speechSpeed} and voice {voice.name}.</p>
                                            <br />
                                            <p className='info infoText'>
                                                <FontAwesomeIcon icon={faCircleInfo} className="poi infoIcon" />
                                                Already visited: Click hear again to start speech synthesis or say "EXPLORER AGAIN"
                                            </p>
                                            {/* {currentVoiceCommand && <p>Last voice command found: {currentVoiceCommand} {currentVoiceCommand.substring(0,currentVoiceCommand.indexOf('-'))}</p>} */}
                                        </div>
                                    }
                                    <h3>{currentPointOfInterest.title}</h3>

                                    {currentPointOfInterest.shortDescription
                                        ? <div className="preline-enabled">
                                            <h4>Short Description</h4>
                                            <p>{currentPointOfInterest.shortDescription}</p>
                                        </div>
                                        : <></>}
                                    {currentPointOfInterest.longDescription
                                        ?
                                        <div className="preline-enabled">
                                            <h4>Long Description</h4>
                                            <p>{currentPointOfInterest.longDescription}</p>

                                        </div>
                                        : <></>
                                    }
                                    {currentPointOfInterest.galleryImages && currentPointOfInterest.galleryImages.length > 0
                                        ?
                                        <div>
                                            <h4>Gallery</h4>
                                            <Gallery images={currentPointOfInterest.galleryImages} onSelect={openModal} />
                                        </div>
                                        : <></>
                                    }
                                    {currentPointOfInterest.references
                                        ?
                                        <div className="preline-enabled">
                                            <h4>References</h4>
                                            <div>{currentPointOfInterest.references}</div>
                                        </div>
                                        : <></>
                                    }
                                    <br />
                                </div>
                                {currentPointOfInterest.likes && currentPointOfInterest.likes.length > 0 &&
                                    <div>
                                        <FontAwesomeIcon icon={faHeart} ></FontAwesomeIcon>
                                        {currentPointOfInterest.likes.length} liked this too.
                                    </div>
                                }
                                <div>


                                    {(!currentPointOfInterest.liked)
                                        ? <Button onClick={like}><FontAwesomeIcon icon={faHeart}></FontAwesomeIcon> Like</Button>
                                        : <Button onClick={unlike}><FontAwesomeIcon icon={faHeartCrack}></FontAwesomeIcon> Unlike</Button>
                                    }

                                    {!currentPointOfInterest.skipped
                                        ? <Button onClick={skip}><FontAwesomeIcon icon={faForward}></FontAwesomeIcon> Skip</Button>
                                        : <Button onClick={unskip}><FontAwesomeIcon icon={faXmark}></FontAwesomeIcon> Unskip</Button>
                                    }

                                    {currentPointOfInterest.visited &&
                                        <Button onClick={() => { unvisit(currentPointOfInterest); }}><FontAwesomeIcon icon={faRepeat}></FontAwesomeIcon> Hear again</Button>
                                    }

                                    {currentPointOfInterest && speaking &&
                                        <Button onClick={() => { stopSpeaking(); }}><FontAwesomeIcon icon={faStop}></FontAwesomeIcon> Stop</Button>
                                    }

                                    {currentPointOfInterest && currentPointOfInterest.shortDescription?.length > 0 &&
                                        <Button onClick={() => { tellShortDescription(); }}><FontAwesomeIcon icon={faComment}></FontAwesomeIcon> Short Description</Button>
                                    }
                                    {currentPointOfInterest && currentPointOfInterest.longDescription?.length > 0 &&
                                        <Button onClick={() => { tellLongDescription(); }}><FontAwesomeIcon icon={faCommentDots}></FontAwesomeIcon> Long Description</Button>
                                    }
                                    {currentPointOfInterest && currentPointOfInterest.images?.length > 0 &&
                                        <Button onClick={() => { tellImagesDescription(); }}><FontAwesomeIcon icon={faCommentDots}></FontAwesomeIcon> Image Description</Button>
                                    }

                                </div>
                            </div>
                        }


                        <div className='exploring-hints'>
                            <Accordion open={openExploreHints} toggle={toggleExploreHints}>
                                <AccordionItem >
                                    <AccordionHeader targetId="1"><FontAwesomeIcon icon={faCircleInfo}></FontAwesomeIcon>&nbsp;Short Introduction for voice commands</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        <VoiceCommandHelp></VoiceCommandHelp>
                                        <div><a href="/introduction">Read more ...</a></div>
                                    </AccordionBody>
                                </AccordionItem>

                            </Accordion>

                        </div>

                        <div className='explore-application-buttons'>
                            <Button onClick={openOverlay}><FontAwesomeIcon icon={faLock}></FontAwesomeIcon> Lock screen</Button>
                            <Button onClick={startListenToSingleCommand} className={listening ? "disabled" : ""}>
                                {!listening
                                    ? <><FontAwesomeIcon icon={faEarDeaf} /> Start listening</>
                                    : <><FontAwesomeIcon icon={faEarListen} /> Currently listening ...</>
                                }
                            </Button>
                        </div>

                        <div>
                            {isExploring ?
                                <div>
                                    <Button className="exploring-button exploring-stop" onClick={stopExploring}>
                                        <FontAwesomeIcon icon={faFlagCheckered} /> Stop Exploring
                                    </Button>
                                </div> :
                                <div>
                                    <Button className="exploring-button exploring-start" onClick={startExploring}>
                                        <FontAwesomeIcon icon={faRoad} /> Start Exploring</Button>
                                </div>}
                        </div>





                        <Overlay
                            configs={overlayConfiguration}
                            isOpen={overlay.isOpen}>
                            <div onClick={handleOverlayTap}>
                                <div>
                                    <PatternLock
                                        width={300}
                                        pointSize={15}
                                        size={3}
                                        path={lockPattern}
                                        onChange={setPattern}
                                        onFinish={handleFinish}
                                    />
                                    <hr className='info-unlock-pattern' />
                                    <div className='info-unlock-pattern'>
                                        <h3>Info to unlock</h3>

                                        <img src={infoUnlockPattern} width="100px" />
                                        <p className="infoText">

                                            <FontAwesomeIcon icon={faCircleInfo} className="cirle-info-icon" />

                                            Draw a line from the <br />
                                            left bottom corner to the right top corner
                                        </p>
                                    </div>
                                    <hr className='info-unlock-pattern' />
                                    <div className='info-unlock-pattern'>
                                        {listening
                                            ? <><h3>Listening ...</h3><FontAwesomeIcon icon={faEarListen} className="listeningIcon"></FontAwesomeIcon></>
                                            : <><h3>Tap to activate voice command</h3><FontAwesomeIcon icon={faEarDeaf} className="listeningIcon"></FontAwesomeIcon></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Overlay>

                    </div>
                    <PointOfInterestModal isOpen={modalState.isOpen} pointOfInterest={currentPointOfInterest} onClosed={closeModal} selectedPictureIndex={modalState.selectedPictureIndex}>
                    </PointOfInterestModal>
                </>
            }
        </div>
    );

}
export default Explore;