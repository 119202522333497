import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPerson, faLocationDot, faLocationPinLock, faLocationPin } from '@fortawesome/free-solid-svg-icons'
import { useUserActions } from '../../common/user-actions';

function PointOfInterestIcon({ pointOfInterest }) {

    PointOfInterestIcon.displayName = PointOfInterestIcon.name;
    const userActions = useUserActions();
    const [currentUser, setCurrentUser] = useState();

    const getPointOfInterestClass = (pointOfInterest) => {
        if (pointOfInterest.visited) {
            return "poi visited";
        }
        else if (pointOfInterest.skipped) {
            return "poi skipped";
        } else if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 0) {
            // Own public poi
            return "poi own public";
        } else if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 1) {
            // Own private poi
            return "poi own private";
        } else {
            return "poi other public";
        }
    };
    
    useEffect(() => {
        setCurrentUser(userActions.currentUser());
    }, []);
    
    if (currentUser != null) {
        if (pointOfInterest.visited) {
            if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 0) {
                return <FontAwesomeIcon icon={faLocationPin} className="poi visited" />;
            }
            else {
                return <FontAwesomeIcon icon={faLocationDot} className="poi visited" />;
            }
        }
        else if (pointOfInterest.skipped) {
            if (pointOfInterest.authorId === currentUser.id ) {
            return <FontAwesomeIcon icon={faLocationDot} className="poi skipped" />;
        }
        else {
            return <FontAwesomeIcon icon={faLocationDot} className="poi skipped" />;
        }
        } else if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 0) {
            // Own public poi
            return <FontAwesomeIcon icon={faLocationDot} className="poi own public" />

        } else if (pointOfInterest.authorId === currentUser.id && pointOfInterest.privacy === 1) {
            // Own private poi
            return <FontAwesomeIcon icon={faLocationPinLock} className="poi own private" />
        } else {
            return <FontAwesomeIcon icon={faLocationPin} className="poi" />;
        }
    }

}

export default PointOfInterestIcon; 