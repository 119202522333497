import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useAuthenticatedFetchApi } from '../../common/authenticated-fetch-api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faLocationDot, faRemove, faGlobe, faTrash, faComments, faBug, faBars } from '@fortawesome/free-solid-svg-icons'
import ReactPaginate from 'react-paginate';
import PointOfInterestIcon from '../MapComponents/PointOfInterestIcon';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useUserActions } from '../../common/user-actions';

function PoiTable({ loadedCallback, fetchDataUrl }) {
    const authenticatedFetchApi = useAuthenticatedFetchApi();
    const userActions = useUserActions();

    const navigate = useNavigate();
    const pageSize = 10;

    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pointsOfInterest, setPointsOfInterest] = useState([]);
    const [currentUser, setCurrentUser] = useState(userActions.currentUser())

    PoiTable.displayName = PoiTable.name;

    useEffect(() => {
        loadPois();
    }, [page]);

    const loadPois = () => {
        const fetchData = async () => {
            const contentView = await authenticatedFetchApi.get(`${fetchDataUrl}?page=${page}&pageSize=${pageSize}`)

            setPointsOfInterest(contentView.pointsOfInterest);
            loadedCallback(contentView.pointsOfInterest);

            setPageCount(contentView.pages);
        }

        fetchData().catch(console.error);
    }

    const handlePageClick = ({ selected }) => {
        setPage(selected);
    };


    const editPointOfInterest = (pointOfInterestId) => {
        navigate(`/quick-add?poi-id=${pointOfInterestId}`);
    };

    const deletePointOfInterest = async (pointOfInterestId) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let deleteResponse = await authenticatedFetchApi.post("pointofinterest/delete", { "pointOfInterestId": pointOfInterestId });

                        if (deleteResponse.success) {
                            loadPois();
                            NotificationManager.success('POI deleted ' + pointOfInterestId);
                        } else {
                            NotificationManager.error('Could not delete POI.');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        NotificationManager.info('Aborted');
                    }
                }
            ]
        });
    };


    return (
        <div>
            {pointsOfInterest ?
                <div>
                    <table className='content-manager-poi-table table table-borderless' aria-labelledby="tableLabel">
                        <thead>
                            <tr>
                                <td><FontAwesomeIcon icon={faGlobe} /></td>
                                <td>Position</td>
                                <td>POI Name</td>
                                <td>Creator</td>
                                <td><FontAwesomeIcon icon={faBars} /></td>
                            </tr>
                        </thead>
                        <tbody>

                            {pointsOfInterest.map(pointOfInterest =>
                                <tr key={pointOfInterest.id}>
                                    <td>
                                        <PointOfInterestIcon pointOfInterest={pointOfInterest}>
                                        </PointOfInterestIcon>
                                    </td>
                                    <td>
                                        <span className='span-coordinate'>{pointOfInterest.latitude}</span><br />
                                        <span className='span-coordinate'>{pointOfInterest.longitude}</span>
                                    </td>
                                    <td><span className='span-title'>{pointOfInterest.title}</span></td>
                                    <td>
                                        <img className='poi-table-author-image' src={pointOfInterest.author ? `/picture/profile-picture/${pointOfInterest.author.id}/default` : ""}></img>
                                        <span className='span-author'>{pointOfInterest.author?.name || "System"}</span></td>
                                    <td>
                                        <Button onClick={() => editPointOfInterest(pointOfInterest.id)}>
                                        {pointOfInterest && pointOfInterest.author?.id === currentUser?.id 
                                            ? <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                                            :<> <FontAwesomeIcon icon={faBug}></FontAwesomeIcon> <FontAwesomeIcon icon={faComments}></FontAwesomeIcon></>
                                        }
                                        </Button>
                                        {((pointOfInterest && pointOfInterest.author?.id === currentUser?.id) || currentUser?.id == -1) &&
                                            <Button onClick={() => deletePointOfInterest(pointOfInterest.id)}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {pageCount > 1 &&
                        <ReactPaginate
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                        />
                    }
                </div>
                :
                <div>
                    Loading points of interest ...
                </div>
            }
        </div>
    );
}

export default PoiTable; 