import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import './custom-font-size-0-small.css';
import './custom-font-size-1-normal.css';
import './custom-font-size-2-large.css';
import './custom-theme-light.css';
import './custom-theme-normal.css';
import './custom-theme-dark.css';
import 'react-notifications/lib/notifications.css';
import "@fontsource/comfortaa";
import { useUserActions } from './common/user-actions';
import { useAuthenticatedFetchApi } from './common/authenticated-fetch-api';

function App() {
  App.displayName = App.name;

  const userActions = useUserActions();
  const authenticatedFetchApi = useAuthenticatedFetchApi();

  //initialize
  useEffect(() => {
    let path = window.location.pathname;

    // remove / at the front
    path = path.substring(1);

    if (path.indexOf("/") > 0) {
      path = path.substring(0, path.indexOf("/"));
    }

    if (path === "") {
      window.document.body.classList.add("login-page");
    } else {
      window.document.body.classList.add("default-page")
    }

    let currentUser = userActions.currentUser();

    if (currentUser) {
      window.document.body.classList.add("font-size-" + currentUser.fontSize);
      window.document.body.classList.add("theme-" + currentUser.theme);
    }
  }, []);

  useEffect(() => {
    window.onerror = async (errorMsg, url, lineNumber) => {
      await authenticatedFetchApi.post("/error/log-error", { message: errorMsg });
    }
  }, []);

  useEffect( () => () => window.onerror = null, [] );

  return (

    <Layout>
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
    </Layout>
  );
}

export default App;