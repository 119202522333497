import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faLocationDot, faLocationPinLock, faLocationPin} from '@fortawesome/free-solid-svg-icons'
import { Badge } from 'reactstrap';

function PoiMarker({ text, nightMode, poiId, own, visited, skipped, privacy }) {
    PoiMarker.displayName = PoiMarker.name;
    
    return (

     
        <div className="poi-container">
            <span className={"poi " + (nightMode ? " night-mode" : " day-mode") + (skipped ? " skipped" : " ") + (visited ? " visited " : " ") + (own ? " own" : " other") + (privacy === 0 ? " public" : " private")} id={"poi-" + poiId}>
                <FontAwesomeIcon className="poiIcon" icon={((privacy === 0)? (own?  faLocationDot : faLocationPin) : faLocationPinLock  ) } />
                {text}
            </span>

        </div>
   

    );
}

export default PoiMarker;